import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";

export default function AdminContent() {
  const metaData = React.useContext(AppMetaDataContext);
  const [languages, setLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchContentStats();
  }, []);

  async function fetchContentStats() {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get("/api/admin/content");
      const sortedLanguages = response.data.languages.sort((a, b) =>
        a.language_name.localeCompare(b.language_name)
      );
      setLanguages(sortedLanguages);
    } catch (err) {
      console.error("Error fetching content statistics:", err);
      setError("Failed to load content statistics");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <Helmet>
        <title>Content Statistics - {metaData.appName}</title>
        <meta name="description" content="Admin content statistics" />
      </Helmet>

      <h2>Content Statistics</h2>

      {isLoading && (
        <Alert variant="info">
          <Spinner animation="border" role="status" size="sm" className="me-2">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          Loading content statistics...
        </Alert>
      )}

      {error && <Alert variant="danger">{error}</Alert>}

      <div className="table-responsive mt-3">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Language</th>
              <th scope="col">Texts</th>
              <th scope="col">Collections</th>
              <th scope="col">Songs</th>
            </tr>
          </thead>
          <tbody>
            {languages.map((lang) => (
              <tr key={lang.language_code}>
                <td>
                  {lang.language_name} ({lang.language_code})
                </td>
                <td className={lang.texts_count === 0 ? "text-secondary" : ""}>
                  {lang.texts_count || 0}
                </td>
                <td
                  className={
                    lang.collections_count === 0 ? "text-secondary" : ""
                  }
                >
                  {lang.collections_count || 0}
                </td>
                <td className={lang.songs_count === 0 ? "text-secondary" : ""}>
                  {lang.songs_count || 0}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Container>
  );
}
