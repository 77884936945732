import { Purchases } from "@revenuecat/purchases-capacitor";
import { Capacitor } from "@capacitor/core";

export const initializeRevenueCat = async () => {
  console.log("RevenueCat: Initializing...");
  if (Capacitor.isNativePlatform()) {
    try {
      await Purchases.configure({
        apiKey: "appl_YrFTCHGkFPModDDYyfjDAaqonIV",
        appUserID: "test_user_123",
      });
      console.log("RevenueCat: Initialized");
    } catch (error) {
      console.error("RevenueCat: Init failed:", error.message);
    }
  }
};

export const fetchOfferings = async () => {
  console.log("RevenueCat: Fetching offerings...");
  try {
    const offerings = await Purchases.getOfferings();
    const packages = offerings?.current?.availablePackages || [];
    console.log(
      "RevenueCat: Offerings fetched:",
      packages.length,
      "packages found"
    );
    return packages;
  } catch (error) {
    console.error("RevenueCat: Fetch offerings failed:", error.message);
    return [];
  }
};

export const purchasePackage = async (packageToPurchase) => {
  console.log("RevenueCat: Starting purchase...");
  try {
    const result = await Purchases.purchasePackage({
      aPackage: packageToPurchase,
    });
    console.log("RevenueCat: Purchase completed");
    return result.customerInfo;
  } catch (error) {
    console.error("RevenueCat: Purchase failed:", error.message);
    throw error;
  }
};
