import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import AppMetaDataContext from "../context/AppMetaDataContext";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Helmet } from "react-helmet-async";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { Link } from "react-router-dom";
export default function Admin() {
  const metaData = React.useContext(AppMetaDataContext);

  // Initialize state
  const [languages, setLanguages] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [feedbackAlert, setFeedbackAlert] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await fetchLanguages(); // Wait for languages to be loaded first
      await fetchArticlesCount(); // Then fetch the article counts
    }
    fetchData();
  }, []);

  async function fetchLanguages() {
    try {
      const response = await axios.get("/api/languages");
      const sortedLanguages = response.data.languages.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const filteredLanguages = sortedLanguages.filter(
        (language) => language.is_learning_language
      );
      setLanguages(filteredLanguages);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  }

  async function fetchArticlesCount() {
    setisLoading(true);
    setFeedbackAlert("Loading article counts...");
    try {
      // Fetch articles count from the backend
      const { data } = await axios.get("/api/languages/articles/count");
      const articlesCountData = data.languages;

      // Update languages state with new article counts
      setLanguages((prevLanguages) => {
        return prevLanguages.map((language) => {
          // Find a matching language in the articles count data
          const found = articlesCountData.find(
            (item) => item.code === language.code
          );
          if (found) {
            console.log(
              "Found language: ",
              language.name,
              " with article count: ",
              found.articleCount
            );
            // Return a new object with updated articleCount
            return { ...language, articleCount: found.articleCount };
          } else {
            console.log("No match found for language: ", language.name);
          }
          // Return the language as is if no match found
          return language;
        });
      });
      setFeedbackAlert(false);
    } catch (error) {
      console.error("Error fetching articles count:", error);
      setFeedbackAlert("Failed to load article counts");
    } finally {
      setisLoading(false);
    }
  }

  async function updateLanguageArticles(language) {
    console.log("Updating articles for language: ", language);
    setisLoading(true);
    setFeedbackAlert("Updating articles for language: " + language);
    // call /api/update-language endpoint with axios
    try {
      console.log("Trying to update articles for language: ", language);
      const response = await axios.post("/api/update-articles", {
        language: language,
      });
      console.log("Got the response from the backend: ", response);
      setisLoading(false);
      setFeedbackAlert(
        `Inserted ${response.data.insertedCount} and ignored ${response.data.ignoredCount} articles for language: ${language}`
      );
    } catch (error) {
      console.error("There was a problem with updating the articles: ", error);
      setisLoading(false);
      setFeedbackAlert(error.message);
    }
  }

  // send axios request to backend for deleting articles
  async function deleteAllOldArticles(language) {
    console.log("Deleting articles...");
    setisLoading(true);
    setFeedbackAlert("Deleting articles...");
    try {
      console.log("Trying to delete articles from the backend.");
      const response = await axios.delete("/api/articles", {
        params: { language },
      });

      console.log("Got the response from the backend: ", response);
      setisLoading(false);
      setFeedbackAlert(
        `Deleted ${response.data.deletedCount} articles from the database.`
      );
    } catch (error) {
      console.error("There was a problem with deleting the articles: ", error);
      setisLoading(false);
      setFeedbackAlert(error.message);
    }
  }

  // Set state with imported articles data
  // useEffect(() => {
  //   // filter out words that have strength and where ignored is not true
  //   const filteredLearningWords = knownWordsArray.filter(
  //     (word) => word.translation
  //   );
  //   setLearningWords(filteredLearningWords);

  //   const filteredSkippedWords = knownWordsArray.filter(
  //     (word) => !word.translation
  //   );
  //   setSkippedWords(filteredSkippedWords);
  // }, [knownWordsArray]);

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <Helmet>
        <title>Admin - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>
      <div>
        <div className="" style={{ maxWidth: "700px" }}>
          {/* <h1 className="mb-5">Vocabulary</h1> */}
        </div>

        {feedbackAlert && (
          <div className="d-flex justify-content-center mb-5">
            <div className="d-flex flex-column">
              <Alert variant="info">
                {isLoading && (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="me-2"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )}
                {feedbackAlert}
              </Alert>
            </div>
          </div>
        )}

        {/* link to translations editor */}
        <Link to="/admin/translations-editor">
          <h3>Translations editor</h3>
        </Link>
        <Link to="/admin/content">
          <h3>Content admin</h3>
        </Link>
        <h3>Language admin</h3>

        <div className="my-3">
          <Button
            className="btn btn-danger"
            onClick={() => {
              console.log("Delete articles button clicked.");
              deleteAllOldArticles();
            }}
            disabled={isLoading}
          >
            Delete all old articles
          </Button>
        </div>

        {/* display words in a table with rows: word, translation, strength */}
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Language</th>
                <th scope="col">Nr of articles</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {languages.map((language, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {language.name} ({language.code})
                    </td>
                    <td>{language.articleCount || 0}</td>
                    <td>
                      {/* Update articles button */}
                      <Button
                        className="btn btn-primary mx-2"
                        size="sm"
                        onClick={() => {
                          console.log("Update articles button clicked.");
                          updateLanguageArticles(language.code);
                        }}
                      >
                        Update articles
                      </Button>
                      <Button
                        className="btn btn-danger mx-2"
                        size="sm"
                        onClick={() => {
                          console.log(
                            "Delete old articles button clicked for: ",
                            language.code
                          );
                          deleteAllOldArticles(language.code);
                        }}
                      >
                        Delete old articles
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
}
