export const recentReleaseNotes = [
  {
    title: "Thai text-to-speech improvements",
    date: "2 Apr 2025",
    updates: ["Thai text-to-speech is now better with a new voice."],
  },
  {
    title: "Images in reader",
    date: "1 Apr 2025",
    updates: ["Images are now displayed in the reader."],
  },
  {
    title: "Listening levels",
    date: "29 Mar 2025",
    updates: ["You can now see your listening levels in the Statistics page."],
  },
  {
    title: "Grammar selection modals now show examples",
    date: "28 Mar 2025",
    updates: [
      "The grammar selection modals now show examples for each tense.",
      "These are available in chat and reader (simplify text).",
    ],
  },
  {
    title: "Simplify texts with AI using a specific grammar tense",
    date: "28 Mar 2025",
    updates: [
      "You can now practice a specific grammar tense by simplifying a text with AI.",
      "Find the new option in the 'Simplify' dropdown in the Reader.",
    ],
  },
  {
    title: "Fixed Library navigation & tab selection",
    date: "27 Mar 2025",
    updates: [
      "The Library navigation is now keeping in mind where you were last.",
    ],
  },
  {
    title: "Updated Thai YouTube channels",
    date: "22 Mar 2025",
    updates: ["Added more language-related channels."],
  },
  {
    title: "Updated Portuguese YouTube channels",
    date: "13 Mar 2025",
    updates: [
      "Added more YouTube channels to the list of channels for the Portuguese language.",
    ],
  },
  {
    title: "Russian UI language",
    date: "7 Mar 2025",
    updates: ["The Russian UI language is now available in beta."],
  },
  {
    title: "60 base languages",
    date: "5 Mar 2025",
    updates: [
      "The app now supports 60 base languages in total.",
      "The number of learning languages did not change.",
    ],
  },
  {
    title: "Reader (finished page) improvements",
    date: "24 Feb 2025",
    updates: ["The page got a small redesign."],
  },
  {
    title: "Import ebooks",
    date: "21 Feb 2025",
    updates: [
      "You can now import ebooks into the library.",
      "The books are split into sections based on the table of contents.",
    ],
  },
  {
    title: "Bug fixes",
    date: "18 Feb 2025",
    updates: [
      "Fixed a bug where the vocabulary export was not working.",
      "The Settings dropdown doesn't overflow on desktop anymore.",
    ],
  },
  {
    title: "French news sources updated",
    date: "16 Feb 2025",
    updates: ["Added better quality news sources to the French language."],
  },
  {
    title: "Import any file for studying",
    date: "14 Feb 2025",
    updates: [
      "You can now import almost any file for studying. This includes PDFs, DOCX, ODT, and more.",
      "The files are automatically converted to an editable text format.",
      "Long texts are automatically split to improve reading experience.",
      "The editor also got some design improvements.",
    ],
  },
  {
    title: "PDF import improvements",
    date: "14 Feb 2025",
    updates: [
      "The PDF import now uses a new parser that can handle more complex PDFs.",
    ],
  },
  {
    title: "New audio player setting",
    date: "13 Feb 2025",
    updates: [
      "The audio player now has a setting for pausing the audio after each sentence.",
      "Fixed a bug where the audio player briefly jumped back to the previous sentence when clicking on a sentence.",
    ],
  },
  {
    title: "German news sources updated",
    date: "13 Feb 2025",
    updates: [
      "Added more news sources to the German language - filtering out the less trustworthy ones.",
    ],
  },
  {
    title: "New English course content",
    date: "13 Feb 2025",
    updates: [
      "Added new stories to the English course.",
      "There's also a new course on American History.",
    ],
  },
  {
    title: "Bug fix: Collection re-ordering",
    date: "13 Feb 2025",
    updates: ["The order of articles in collections is now saved correctly."],
  },
  {
    title: "Load speed improvements",
    date: "12 Feb 2025",
    updates: [
      "The app should now load faster in general.",
      "The images uploaded by users are now optimized for web use and load faster.",
    ],
  },
  {
    title: "Chrome extension improvements for word sync",
    date: "12 Feb 2025",
    updates: [
      "The words get automatically synced to the extension after importing from Duolingo.",
      "This also happens after logging in to the Chrome extension.",
    ],
  },
  {
    title: "German YouTube channels updated",
    date: "12 Feb 2025",
    updates: [
      "Added more YouTube channels to the list of channels for the German language.",
    ],
  },
  {
    title: "PDF import",
    date: "11 Feb 2025",
    updates: [
      "You can now import PDF files into the library.",
      "Find the button next to the 'Content' field in the importer and editor.",
    ],
  },
  {
    title: "Vocabulary export",
    date: "10 Feb 2025",
    updates: [
      "You can now export your vocabulary by copying it to the clipboard or downloading it as a tab-separated text file.",
    ],
  },
  {
    title: "Chat design improvements",
    date: "9 Feb 2025",
    updates: [
      "The mobile version now displays a bit more text per line and the send button is smaller.",
    ],
  },
  {
    title: "Estonian news improvements",
    date: "8 Feb 2025",
    updates: [
      "Cleaned up the content of some article sources to not include the comments.",
    ],
  },
  // Add more recent updates here
];

export const getLatestUpdate = () => {
  return recentReleaseNotes[0];
};

export const getLatestUpdates = (count = 3) => {
  return recentReleaseNotes.slice(0, count);
};
