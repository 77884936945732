import { useTranslation } from "react-i18next";
import i18next from "i18next";

/**
 * Determines the similarity label for the learning language based on the base language.
 *
 * @param {Object} language_learning - The language being learned, with a `name` and `group` property.
 * @param {Object} language_base - The base language to compare against, with a `group` property.
 * @returns {string} The name of the learning language with a similarity indicator if applicable.
 */
export function getLanguageLabel(language_learning, language_base) {
  // Check if language_learning is undefined
  // console.log("language_learning: ", language_learning);
  // console.log("language_base: ", language_base);
  if (typeof language_learning === "undefined" || !language_learning) {
    return language_base.name; // Return base language name if learning language is undefined
  }
  // Safely extract group properties, defaulting to empty objects if not present
  const learningGroup = language_learning.group || {};
  const baseGroup = language_base.group || {};

  // Extract family and branch, defaulting to empty strings if not present
  const learningFamily = learningGroup.family || "";
  const learningBranch = learningGroup.branch || "";
  const baseFamily = baseGroup.family || "";
  const baseBranch = baseGroup.branch || "";

  // Check if branches match and are not empty
  if (learningBranch && baseBranch && learningBranch === baseBranch) {
    return `${language_base.name} - ${i18next.t("account:language_list_very_similar")} 🎯`;
  }
  // Check if families match and are not empty
  else if (learningFamily && baseFamily && learningFamily === baseFamily) {
    return `${language_base.name} - ${i18next.t("account:language_list_related")} 🔗 `;
  }
  // If neither branches nor families match, return just the name
  else {
    return language_base.name;
  }
}
