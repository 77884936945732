import React, { useState, useEffect } from "react";
import { Alert, Button, Row, Nav, Spinner, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LibraryCard from "./LibraryCard";
import CollectionCard from "./CollectionCard";
import axios from "axios";
import { FunnelFill, Magic } from "react-bootstrap-icons";
import Badge from "react-bootstrap/Badge";
import { debounce } from "lodash";

const LibraryTabReading = ({
  checkIfArticleIsBookmarked,
  languageLearningCode,
  context,
  activeSubTab,
  onSubTabSelect,
}) => {
  const { t } = useTranslation();

  // News states
  const [articles, setArticles] = useState([]);
  const [articlesAreLoading, setArticlesAreLoading] = useState(false);
  const [currentArticlesPage, setCurrentArticlesPage] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [categories, setCategories] = useState([]);
  const [showNewsFilterModal, setShowNewsFilterModal] = useState(false);
  const [showStoryDifficultyModal, setShowStoryDifficultyModal] =
    useState(false);
  const [maxNewWordsPercentageForStories, setMaxNewWordsPercentageForStories] =
    useState(100);
  const [
    tempMaxNewWordsPercentageForStories,
    setTempMaxNewWordsPercentageForStories,
  ] = useState(100);

  // Stories states
  const [stories, setStories] = useState([]);
  const [storiesAreLoading, setStoriesAreLoading] = useState(false);
  const [currentStoriesPage, setCurrentStoriesPage] = useState(1);

  // Collections states
  const [collections, setCollections] = useState([]);
  const [collectionsAreLoading, setCollectionsAreLoading] = useState(false);

  // Fetch categories
  async function getAllCategories() {
    try {
      const response = await axios.get("/api/categories", {
        params: {
          language: context.settings?.ui_language,
        },
      });
      const allCategories = response.data.categories;
      const categoriesArray = allCategories
        .map((category) => ({
          id: category._id,
          name: category.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setCategories(categoriesArray);

      if (context.settings?.interest_categories?.length > 0) {
        const userInterestIds = new Set(context.settings.interest_categories);
        setSelectedCategories(userInterestIds);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }

  // Get articles
  async function getArticles(selectedCategoryIds = []) {
    setArticlesAreLoading(true);
    try {
      const response = await axios.get("/api/articles", {
        params: {
          language: languageLearningCode,
          page: currentArticlesPage,
          limit: 30,
          categories:
            selectedCategoryIds.length > 0
              ? selectedCategoryIds.join(",")
              : context.settings.interest_categories.join(","),
        },
      });

      if (response.data && Array.isArray(response.data.articles)) {
        setArticles((prevArticles) => [
          ...prevArticles,
          ...response.data.articles,
        ]);
        setCurrentArticlesPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setArticlesAreLoading(false);
    }
  }

  // Get stories
  async function getStories(selectedCategoryIds = []) {
    setStoriesAreLoading(true);
    try {
      const response = await axios.get("/api/articles", {
        params: {
          language: languageLearningCode,
          page: currentStoriesPage,
          limit: 30,
          content_type: "story",
          categories:
            selectedCategoryIds.length > 0
              ? selectedCategoryIds.join(",")
              : context.settings.interest_categories.join(","),
        },
      });

      if (Array.isArray(response.data.articles)) {
        setStories((prevStories) => [
          ...prevStories,
          ...response.data.articles,
        ]);
        setCurrentStoriesPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching stories:", error);
    } finally {
      setStoriesAreLoading(false);
    }
  }

  // Get collections
  function getCollections() {
    setCollectionsAreLoading(true);
    axios
      .get("/api/collections", {
        params: {
          language: languageLearningCode,
        },
      })
      .then((res) => {
        setCollections(res.data.collections);
        setCollectionsAreLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching collections:", error);
        setCollectionsAreLoading(false);
      });
  }

  // Handle category toggle
  const handleCategoryToggle = (categoryId) => {
    const newSelectedCategories = new Set(selectedCategories);
    if (newSelectedCategories.has(categoryId)) {
      newSelectedCategories.delete(categoryId);
    } else {
      newSelectedCategories.add(categoryId);
    }
    setSelectedCategories(newSelectedCategories);
  };

  // Debounce the update function for stories
  const debouncedSetMaxNewWordsPercentageForStories = debounce(
    (value) => setMaxNewWordsPercentageForStories(value),
    300
  );

  const handleStorySliderChange = (e) => {
    const value = Number(e.target.value);
    setTempMaxNewWordsPercentageForStories(value);
    debouncedSetMaxNewWordsPercentageForStories(value);
  };

  useEffect(() => {
    getAllCategories();
  }, [context.getSelectedLanguagePair().language_learning.code]);

  useEffect(() => {
    if (activeSubTab === "news" && articles.length === 0) {
      getArticles();
    } else if (activeSubTab === "stories" && stories.length === 0) {
      getStories();
    } else if (activeSubTab === "collections") {
      getCollections();
    }
  }, [activeSubTab]);

  return (
    <>
      <Nav variant="pills" className="mb-3">
        <Nav.Item>
          <Nav.Link
            eventKey="news"
            active={activeSubTab === "news"}
            onClick={() => onSubTabSelect("news")}
            className={
              activeSubTab === "news" ? "bg-warning text-dark" : "text-dark"
            }
          >
            {t("library:news")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="stories"
            active={activeSubTab === "stories"}
            onClick={() => onSubTabSelect("stories")}
            className={
              activeSubTab === "stories" ? "bg-warning text-dark" : "text-dark"
            }
          >
            {t("library:stories")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="collections"
            active={activeSubTab === "collections"}
            onClick={() => onSubTabSelect("collections")}
            className={
              activeSubTab === "collections"
                ? "bg-warning text-dark"
                : "text-dark"
            }
          >
            {t("library:collections")}
          </Nav.Link>
        </Nav.Item>
      </Nav>

      {(activeSubTab === "news" || activeSubTab === "stories") && (
        <>
          <div className="mb-3 d-flex flex-wrap gap-2">
            <Button
              variant="outline-secondary"
              onClick={() => setShowNewsFilterModal(true)}
              className="d-flex align-items-center gap-2 btn-sm"
            >
              <FunnelFill />
              {t("library:filter_by_category")}
              {selectedCategories.size > 0 && (
                <Badge bg="secondary">{selectedCategories.size}</Badge>
              )}
            </Button>

            {context.known_words?.length > 0 && (
              <Button
                variant="outline-secondary"
                onClick={() => setShowStoryDifficultyModal(true)}
                className="d-flex align-items-center gap-2 btn-sm"
              >
                <FunnelFill />
                {t("library:filter_button_new_words")}
                {maxNewWordsPercentageForStories < 100 && (
                  <Badge bg="secondary">
                    {" <"}
                    {maxNewWordsPercentageForStories}%
                  </Badge>
                )}
              </Button>
            )}
          </div>

          {activeSubTab === "news" && (
            <div className={`${!context.known_words && "d-none mt-2"}`}>
              <div className="d-flex align-items-center mb-4">
                <Form>
                  <Form.Check
                    type="switch"
                    id="translate-titles-switch"
                    checked={
                      context.settings.show_translated_news_article_titles
                    }
                    onChange={() =>
                      context.toggleShowTranslatedNewsArticleTitles()
                    }
                    onClick={(e) => e.stopPropagation()}
                  />
                </Form>
                <h6 className="me-2 mb-0">
                  {t("library:translate_titles_to_english")}
                </h6>
              </div>
            </div>
          )}
        </>
      )}

      {/* News Tab Content */}
      {activeSubTab === "news" && (
        <>
          <Row>
            {articles.map((article, index) => (
              <LibraryCard
                article={article}
                index={index}
                key={index}
                is_imported={false}
                is_bookmarked={checkIfArticleIsBookmarked(article._id)}
                maxNewWordsPercentage={maxNewWordsPercentageForStories}
              />
            ))}
          </Row>
          <Row>
            <div className="col">
              <Button
                onClick={() => getArticles(Array.from(selectedCategories))}
                variant="outline-dark"
                disabled={articlesAreLoading}
              >
                {articlesAreLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />
                    {t("common:loading")}
                  </>
                ) : (
                  t("library:load_more")
                )}
              </Button>
            </div>
          </Row>
        </>
      )}

      {/* Stories Tab Content */}
      {activeSubTab === "stories" && (
        <>
          <Row>
            {stories.map((story, index) => (
              <LibraryCard
                article={story}
                index={index}
                key={index}
                is_imported={false}
                is_bookmarked={checkIfArticleIsBookmarked(story._id)}
                maxNewWordsPercentage={maxNewWordsPercentageForStories}
              />
            ))}
          </Row>
          <Row>
            <div className="col">
              <Button
                onClick={() => getStories(Array.from(selectedCategories))}
                variant="outline-dark"
                disabled={storiesAreLoading}
              >
                {storiesAreLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />
                    {t("common:loading")}
                  </>
                ) : (
                  t("library:load_more")
                )}
              </Button>
              {!storiesAreLoading && stories.length === 0 && (
                <Alert variant="secondary" className="mt-4">
                  &#128566; {t("library:reading_tab_no_stories_to_show_yet")}
                </Alert>
              )}
            </div>
          </Row>
        </>
      )}

      {/* Collections Tab Content */}
      {activeSubTab === "collections" && (
        <>
          <div>
            <Link to="/library/collection/editor">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center btn-dark"
              >
                &#43; {t("library:create_collection")}
              </Button>
            </Link>
            <Link to="/library/import-book">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center btn-dark"
              >
                &#43; {t("library:button_import_book")}
              </Button>
            </Link>
          </div>
          {collectionsAreLoading && (
            <Spinner animation="border" variant="dark" className="mt-4" />
          )}
          {!collectionsAreLoading && collections.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; {t("library:no_collections_to_show_yet")}
            </Alert>
          )}
          <Row className="mb-5 mt-4">
            {collections.map((collection, index) => (
              <CollectionCard
                collection={collection}
                index={index}
                key={index}
              />
            ))}
          </Row>
        </>
      )}

      {/* Filter Modals */}
      <Modal
        show={showNewsFilterModal}
        onHide={() => setShowNewsFilterModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("library:filter_by_category")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap gap-2">
            {categories.map((category, index) => (
              <div className="form-check" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`category-${index}`}
                  checked={selectedCategories.has(category.id)}
                  onChange={() => handleCategoryToggle(category.id)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`category-${index}`}
                >
                  {category.name}
                </label>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowNewsFilterModal(false)}
          >
            {t("common:cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              context.updateInterestCategories(Array.from(selectedCategories));
              setArticles([]);
              setCurrentArticlesPage(1);
              getArticles(Array.from(selectedCategories));
              setShowNewsFilterModal(false);
            }}
          >
            {t("common:apply")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showStoryDifficultyModal}
        onHide={() => setShowStoryDifficultyModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("library:filter_by_difficulty")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3">
            <label
              htmlFor="maxNewWordsPercentageForStories"
              className="form-label"
            >
              {t("library:max_new_words_in_an_article")}:{" "}
              {tempMaxNewWordsPercentageForStories}%
            </label>
            <input
              type="range"
              className="form-range"
              id="maxNewWordsPercentageForStories"
              min="0"
              max="100"
              step="10"
              value={tempMaxNewWordsPercentageForStories}
              onChange={handleStorySliderChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowStoryDifficultyModal(false)}
          >
            {t("common:close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LibraryTabReading;
