import React, { useState, useEffect } from "react";
import { Button, Card, Container, Dropdown } from "react-bootstrap";
import SpeechButton from "../Components/SpeechButton";

const thaiSyllables = [
  {
    syllable: "กะ",
    consonant: "ก",
    ipa: "kà",
    ipaConsonant: "k",
    tone: "l",
    translation: "particle",
    group: 2,
  },
  {
    syllable: "กา",
    consonant: "ก",
    ipa: "kaː",
    ipaConsonant: "k",
    tone: "m",
    translation: "crow",
    group: 2,
  },
  {
    syllable: "จะ",
    consonant: "จ",
    ipa: "tɕà",
    ipaConsonant: "tɕ",
    tone: "l",
    translation: "will",
    group: 2,
  },
  {
    syllable: "จา",
    consonant: "จ",
    ipa: "tɕaː",
    ipaConsonant: "tɕ",
    tone: "m",
    translation: "record",
    group: 2,
  },
  {
    syllable: "ตะ",
    consonant: "ต",
    ipa: "tà",
    ipaConsonant: "t",
    tone: "l",
    translation: "drumstick",
    group: 1,
  },
  {
    syllable: "ตา",
    consonant: "ต",
    ipa: "taː",
    ipaConsonant: "t",
    tone: "m",
    translation: "eye",
    group: 1,
  },
  {
    syllable: "ปะ",
    consonant: "ป",
    ipa: "pà",
    ipaConsonant: "p",
    tone: "l",
    translation: "to meet",
    group: 2,
  },
  {
    syllable: "ปา",
    consonant: "ป",
    ipa: "paː",
    ipaConsonant: "p",
    tone: "m",
    translation: "to throw",
    group: 2,
  },
  {
    syllable: "นะ",
    consonant: "น",
    ipa: "nà",
    ipaConsonant: "n",
    tone: "l",
    translation: "softener",
    group: 2,
  },
  {
    syllable: "นา",
    consonant: "น",
    ipa: "naː",
    ipaConsonant: "n",
    tone: "m",
    translation: "field",
    group: 2,
  },
  {
    syllable: "ละ",
    consonant: "ล",
    ipa: "là",
    ipaConsonant: "l",
    tone: "l",
    translation: "to abandon",
    group: 2,
  },
  {
    syllable: "ลา",
    consonant: "ล",
    ipa: "laː",
    ipaConsonant: "l",
    tone: "m",
    translation: "donkey / to say goodbye",
    group: 2,
  },
  {
    syllable: "สะ",
    consonant: "ส",
    ipa: "sà",
    ipaConsonant: "s",
    tone: "l",
    translation: "prefix (clean), ha!, oh!",
    group: 2,
  },
  {
    syllable: "สา",
    consonant: "ส",
    ipa: "saː",
    ipaConsonant: "s",
    tone: "m",
    translation: "to pray",
    group: 2,
  },
  {
    syllable: "เต",
    consonant: "ต",
    ipa: "teː",
    ipaConsonant: "t",
    tone: "m",
    translation: "soft-shelled turtle",
    group: 1,
  },
  {
    syllable: "เม",
    consonant: "ม",
    ipa: "meː",
    ipaConsonant: "m",
    tone: "m",
    translation: "to boast",
    group: 2,
  },
  {
    syllable: "แท",
    consonant: "ท",
    ipa: "tʰɛː",
    ipaConsonant: "tʰ",
    tone: "m",
    translation: "real, true",
    group: 1,
  },
  {
    syllable: "แพ",
    consonant: "พ",
    ipa: "pʰɛː",
    ipaConsonant: "pʰ",
    tone: "m",
    translation: "raft",
    group: 2,
  },
  {
    syllable: "โต",
    consonant: "ต",
    ipa: "toː",
    ipaConsonant: "t",
    tone: "m",
    translation: "to grow",
    group: 1,
  },
  {
    syllable: "โบ",
    consonant: "บ",
    ipa: "boː",
    ipaConsonant: "b",
    tone: "m",
    translation: "bow, ribbon",
    group: 2,
  },
  {
    syllable: "เพ",
    consonant: "พ",
    ipa: "pʰeː",
    ipaConsonant: "pʰ",
    tone: "m",
    translation: "only",
    group: 2,
  },
  {
    syllable: "เธ",
    consonant: "ธ",
    ipa: "tʰɤː",
    ipaConsonant: "tʰ",
    tone: "m",
    translation: "you",
    group: 1,
  },
  {
    syllable: "โท",
    consonant: "ท",
    ipa: "tʰoː",
    ipaConsonant: "tʰ",
    tone: "m",
    translation: "to phone",
    group: 1,
  },
  {
    syllable: "แจ",
    consonant: "จ",
    ipa: "tɕɛː",
    ipaConsonant: "tɕ",
    tone: "m",
    translation: "clear, bright",
    group: 2,
  },
  {
    syllable: "แม",
    consonant: "ม",
    ipa: "mɛː",
    ipaConsonant: "m",
    tone: "m",
    translation: "mother (short for แม่)",
    group: 2,
  },
];

function ThaiConsonants() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedTone, setSelectedTone] = useState(null);
  const [correctTone, setCorrectTone] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(1);

  const getFilteredSyllables = () => {
    return thaiSyllables.filter((syllable) => syllable.group === selectedGroup);
  };

  useEffect(() => {
    const filteredSyllables = getFilteredSyllables();
    setCurrentIndex(Math.floor(Math.random() * filteredSyllables.length));
  }, [selectedGroup]);

  const filteredSyllables = getFilteredSyllables();
  const currentSyllable = filteredSyllables[currentIndex];

  const getConsonantColor = () => "text-success"; // Highlight consonant in green as it's middle consonant

  const renderSyllable = (syllableObj) => {
    return syllableObj.syllable.split("").map((char, index) => {
      const isConsonant = syllableObj.consonant.includes(char);
      return (
        <span key={index} className={isConsonant ? getConsonantColor() : ""}>
          {char}
        </span>
      );
    });
  };

  const renderIPA = (syllableObj) => {
    return syllableObj.ipa.split("").map((char, index) => {
      const isConsonant = syllableObj.ipaConsonant.includes(char);
      return (
        <span key={index} className={isConsonant ? getConsonantColor() : ""}>
          {char}
        </span>
      );
    });
  };

  const tones = [
    { name: "High", value: "h" },
    { name: "Falling", value: "f" },
    { name: "Middle", value: "m" },
    { name: "Rising", value: "r" },
    { name: "Low", value: "l" },
  ];

  const handleGuess = (guessedTone) => {
    const filteredSyllables = getFilteredSyllables();
    const correct = filteredSyllables[currentIndex].tone;
    setSelectedTone(guessedTone);
    setCorrectTone(correct);

    setTimeout(() => {
      let nextIndex;
      do {
        nextIndex = Math.floor(Math.random() * filteredSyllables.length);
      } while (nextIndex === currentIndex && filteredSyllables.length > 1);
      setCurrentIndex(nextIndex);
      setSelectedTone(null);
      setCorrectTone(null);
    }, 1000);
  };

  return (
    <Container className="mt-5 d-flex flex-column align-items-center">
      <Dropdown className="mb-3">
        <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
          Group {selectedGroup}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setSelectedGroup(1)}>
            Group 1
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSelectedGroup(2)}>
            Group 2
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Card
        className="text-center p-4"
        style={{ minWidth: "300px", maxWidth: "400px" }}
      >
        <Card.Body>
          <Card.Title className="display-4">
            {renderSyllable(currentSyllable)}
          </Card.Title>
          <Card.Text className="fs-3">{renderIPA(currentSyllable)}</Card.Text>
          <Card.Text className="fs-5 text-muted">
            {currentSyllable.translation}
          </Card.Text>

          <SpeechButton word={currentSyllable.syllable} speakOnRender={true} />

          <div className="mt-4 d-flex flex-column align-items-center">
            {tones.map((tone) => (
              <Button
                key={tone.value}
                variant={
                  selectedTone === tone.value
                    ? selectedTone === correctTone
                      ? "success"
                      : "danger"
                    : correctTone === tone.value
                    ? "success"
                    : "outline-dark"
                }
                onClick={() => handleGuess(tone.value)}
                style={{ width: "100px", margin: "5px 0" }}
                size="sm"
              >
                {tone.name}
              </Button>
            ))}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ThaiConsonants;
