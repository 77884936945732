import React, { useState, useEffect } from "react";
// NOTE: look up codes here: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
import ReactCountryFlag from "react-country-flag";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LanguageSupport = () => {
  const [languages, setLanguages] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get("/api/languages")
      .then((response) => {
        const sortedLanguages = response.data.languages
          .filter((language) => language.is_learning_language)
          .sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        setLanguages(sortedLanguages);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []);

  return (
    <>
      {/* <h4 className="text-center mt-4">Supported languages</h4> */}
      <div
        className="my-3 px-3"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {languages.map((language, index) => (
          <div
            key={index}
            className="mx-2 my-2"
            style={{
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`/register`);
            }}
          >
            <ReactCountryFlag
              countryCode={language.flag_code}
              svg
              style={{ marginRight: "5px" }}
            />
            <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>
              {language.name}
            </span>
          </div>
        ))}
      </div>
      <hr className="mb-5" />
    </>
  );
};

export default LanguageSupport;
