import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

// Get browser language as fallback
const getBrowserLang = () => {
  console.log("Navigator language: ", navigator.language);
  return "en-US";
  // TODO: Right now I don't want to set non-logged in users to Spanish, so I'm just going to set it to English
  return navigator.language.slice(0, 2) === "es" ? "es-419" : "en-US";
};

// Initialize with browser language first
i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    lng: getBrowserLang(),
    supportedLngs: ["en-US", "es-419", "ru"],
    fallbackLng: {
      "en-US": [],
      "es-419": ["en-US"],
      "ru": ["en-US"],
      default: ["en-US"],
    },
    load: "currentOnly",
    nonExplicitSupportedLngs: false,
    backend: {
      loadPath: "/api/localization/locales/{{lng}}/{{ns}}",
    },
    defaultNS: "common",
    partialBundledLanguages: true,
    ns: [
      "common",
      "navigation",
      "home",
      "account",
      "library",
      "statistics",
      "onboarding",
      "flashcards",
      "readers",
      "grammar_guide",
      "chat",
      "pricing",
      "vocabulary",
    ],
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
    keySeparator: false,
    returnEmptyString: false,
  });

// Then fetch and update language preference
fetch("/api/user/language")
  .then((response) => response.json())
  .then(({ language }) => {
    // Only change language if it's different from current
    if (language && language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  })
  .catch((error) => {
    console.warn("Failed to fetch language preference:", error);
  });

// log to console that i18n is set up
console.log("i18n Config:", i18n.options);

export default i18n;
