function splitThaiWordIntoLetters(word) {
  // Match a Thai character plus any following tone marks or modifiers
  const pattern = /[\u0E00-\u0E7F][\u0E31-\u0E3A\u0E47-\u0E4E]?/g;
  return word.match(pattern) || [];
}

// Example usage
const word = "ไม้เอก";
const result = splitThaiWordIntoLetters(word);
console.log(result); // Output: ['ไ', 'ม้', 'เ', 'ก']


const CONSONANTS = /[\u0E01-\u0E2E\u0E9F-\u0EAE]/;
const VOWELS = /[\u0E30-\u0E39\u0E40-\u0E4C]/;
const TONE_MARKS = /[\u0E48-\u0E4B]/;

const splitThaiWordIntoGraphemes = (word) => {
  const graphemes = [];
  let current = '';
  
  for (const char of word) {
    if (CONSONANTS.test(char) || VOWELS.test(char) || TONE_MARKS.test(char)) {
      if (current) graphemes.push(current);
      current = char;
    } else {
      current += char;
    }
  }
  
  if (current) graphemes.push(current);
  
  return graphemes;
};

console.log(splitThaiWordIntoGraphemes(word)); // Output: ["ป", "ื", "อ", "ย"]

export { splitThaiWordIntoGraphemes, splitThaiWordIntoLetters };