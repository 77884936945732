import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ListGroup,
  Card,
  Badge,
  Dropdown,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Pencil, Trash, Check, PlusLg } from "react-bootstrap-icons";
import { splitThaiWordIntoLetters } from "../utils/ThaiLanguageUtils";
import { analyzeSyllable } from "../utils/ThaiTonesUtils";
import SpeechButton from "../Components/SpeechButton";
import axios from "axios";

const API_BASE_URL = "/api/thai";

const api = {
  // Word operations
  getWords: () => axios.get(API_BASE_URL),
  createWord: (word) => axios.post(`${API_BASE_URL}/word`, word),
  updateWord: (id, word) => axios.put(`${API_BASE_URL}/word/${id}`, word),
  deleteWord: (id) => axios.delete(`${API_BASE_URL}/word/${id}`),

  // Group operations
  getGroups: () => axios.get(`${API_BASE_URL}/groups`),
  createGroup: (group) => axios.post(`${API_BASE_URL}/group`, group),
  updateGroup: (id, group) => axios.put(`${API_BASE_URL}/group/${id}`, group),
  deleteGroup: (id) => axios.delete(`${API_BASE_URL}/group/${id}`),
};

const ThaiAdmin = () => {
  const [words, setWords] = useState([]);
  const [newWord, setNewWord] = useState("");
  const [editingWordIndex, setEditingWordIndex] = useState(null);

  // Tone options
  const toneOptions = [
    { code: "m", name: "Mid" },
    { code: "l", name: "Low" },
    { code: "h", name: "High" },
    { code: "f", name: "Falling" },
    { code: "r", name: "Rising" },
  ];

  // Consonant class options
  const consonantClassOptions = [
    { code: "low", name: "Low" },
    { code: "mid", name: "Mid" },
    { code: "high", name: "High" },
  ];

  // Update the groups state to store full group objects instead of just names
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState("");
  const [selectedGroups, setSelectedGroups] = useState({}); // Maps word index to an array of group names

  // Add loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Add new state for temporary translation values
  const [tempTranslations, setTempTranslations] = useState({});

  // Add new state for temporary IPA values
  const [tempIPAs, setTempIPAs] = useState({});

  // Add these new state variables near other state declarations
  const [editingGroups, setEditingGroups] = useState({});
  const [tempGroupEdits, setTempGroupEdits] = useState({});

  // Update the useEffect that fetches initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [wordsResponse, groupsResponse] = await Promise.all([
          api.getWords(),
          api.getGroups(),
        ]);
        setWords(wordsResponse.data);
        setGroups(groupsResponse.data); // Store complete group objects
        setError(null);
      } catch (err) {
        setError("Failed to fetch data");
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to split Thai word into graphemes
  const splitThaiWordIntoGraphemes = (word) => {
    const CONSONANTS = /[\u0E01-\u0E2E\u0E9F-\u0EAE]/;
    const VOWELS = /[\u0E30-\u0E39\u0E40-\u0E4C]/;
    const TONE_MARKS = /[\u0E48-\u0E4B]/;

    const graphemes = [];
    let current = "";

    for (const char of word) {
      if (CONSONANTS.test(char) || VOWELS.test(char) || TONE_MARKS.test(char)) {
        if (current) graphemes.push(current);
        current = char;
      } else {
        current += char;
      }
    }

    if (current) graphemes.push(current);

    return graphemes;
  };

  // Update handleAddWord
  const handleAddWord = async (e) => {
    e.preventDefault();
    if (newWord.trim()) {
      try {
        const letters = splitThaiWordIntoLetters(newWord);
        const graphemes = splitThaiWordIntoGraphemes(newWord);
        const analysis = analyzeSyllable(newWord);

        const newWordObj = {
          syllable: newWord,
          letters,
          graphemes,
          translation: "",
          ipa: [],
          positions: {
            initial_consonant: null,
            vowel: [],
            final_consonant: [],
          },
          positions_graphemes: {
            initial_consonant: null,
            vowel: [],
            final_consonant: [],
          },
          tone: analysis.tone || "",
          consonantClass: analysis.consonantClass || "",
          analysis,
          groups: [], // Initialize empty groups array
        };

        const response = await api.createWord(newWordObj);
        setWords([...words, response.data]);
        setNewWord("");
        setEditingWordIndex(words.length);
        setError(null);
      } catch (err) {
        setError("Failed to add word");
        console.error("Error adding word:", err);
      }
    }
  };

  // Update handleDeleteWord
  const handleDeleteWord = async (wordIndex) => {
    if (
      window.confirm(
        `Are you sure you want to delete "${words[wordIndex].syllable}"?`
      )
    ) {
      try {
        await api.deleteWord(words[wordIndex]._id);
        const updatedWords = [...words];
        updatedWords.splice(wordIndex, 1);
        setWords(updatedWords);

        if (editingWordIndex === wordIndex) {
          setEditingWordIndex(null);
        } else if (editingWordIndex > wordIndex) {
          setEditingWordIndex(editingWordIndex - 1);
        }
        setError(null);
      } catch (err) {
        setError("Failed to delete word");
        console.error("Error deleting word:", err);
      }
    }
  };

  // Toggle editing mode for a word
  const toggleEditWord = (index) => {
    setEditingWordIndex(editingWordIndex === index ? null : index);
  };

  // Update setInitialConsonantPosition
  const setInitialConsonantPosition = async (wordIndex, position) => {
    const updatedPositions = {
      ...words[wordIndex].positions,
      initial_consonant: position,
    };
    const updatedPositionsGraphemes = {
      ...words[wordIndex].positions_graphemes,
      initial_consonant: position,
    };
    await updateWord(wordIndex, {
      positions: updatedPositions,
      positions_graphemes: updatedPositionsGraphemes,
    });
  };

  // Update toggleVowelPosition
  const toggleVowelPosition = async (wordIndex, position) => {
    const currentVowelPositions = words[wordIndex].positions.vowel;
    const updatedVowelPositions = currentVowelPositions.includes(position)
      ? currentVowelPositions.filter((pos) => pos !== position)
      : [...currentVowelPositions, position];

    const updatedPositions = {
      ...words[wordIndex].positions,
      vowel: updatedVowelPositions,
    };
    const updatedPositionsGraphemes = {
      ...words[wordIndex].positions_graphemes,
      vowel: updatedVowelPositions,
    };

    await updateWord(wordIndex, {
      positions: updatedPositions,
      positions_graphemes: updatedPositionsGraphemes,
    });
  };

  // Update handleAddGroup
  const handleAddGroup = async (e) => {
    e.preventDefault();
    if (newGroup.trim()) {
      try {
        const response = await api.createGroup({
          name: newGroup.trim(),
          description: "",
        });
        setGroups([...groups, response.data]); // Add complete group object
        setNewGroup("");
        setError(null);
      } catch (err) {
        setError("Failed to add group");
        console.error("Error adding group:", err);
      }
    }
  };

  // Update handleGroupSelect
  const handleGroupSelect = async (wordIndex, groupId) => {
    const currentGroups = words[wordIndex].groups || [];
    const updatedGroups = currentGroups.includes(groupId)
      ? currentGroups.filter((id) => id !== groupId)
      : [...currentGroups, groupId];

    await updateWord(wordIndex, { groups: updatedGroups });
  };

  // Add function to update word
  const updateWord = async (wordIndex, updatedFields) => {
    try {
      const wordId = words[wordIndex]._id;
      const updatedWord = { ...words[wordIndex], ...updatedFields };
      const response = await api.updateWord(wordId, updatedWord);

      const updatedWords = [...words];
      updatedWords[wordIndex] = response.data;
      setWords(updatedWords);
      setError(null);
    } catch (err) {
      setError("Failed to update word");
      console.error("Error updating word:", err);
    }
  };

  // Update existing update functions to use the new updateWord function
  const updateTone = (wordIndex, newTone) => {
    updateWord(wordIndex, { tone: newTone });
  };

  const updateConsonantClass = (wordIndex, newClass) => {
    updateWord(wordIndex, { consonantClass: newClass });
  };

  // Add this new function
  const updateIPA = (wordIndex, ipaString) => {
    updateWord(wordIndex, { ipa: ipaString.split(",").map((s) => s.trim()) });
  };

  // Update the translation handling functions
  const handleTranslationChange = (wordIndex, newTranslation) => {
    setTempTranslations({
      ...tempTranslations,
      [wordIndex]: newTranslation,
    });
  };

  const handleTranslationSave = async (wordIndex) => {
    const newTranslation = tempTranslations[wordIndex];
    if (newTranslation !== undefined) {
      await updateWord(wordIndex, { translation: newTranslation });
      // Clear the temporary translation after saving
      const updatedTempTranslations = { ...tempTranslations };
      delete updatedTempTranslations[wordIndex];
      setTempTranslations(updatedTempTranslations);
    }
  };

  // Get tone badge color
  const getToneBadgeColor = (tone) => {
    switch (tone) {
      case "m":
        return "primary"; // mid tone - blue
      case "l":
        return "info"; // low tone - light blue
      case "h":
        return "warning"; // high tone - yellow
      case "f":
        return "danger"; // falling tone - red
      case "r":
        return "success"; // rising tone - green
      default:
        return "secondary"; // unknown - gray
    }
  };

  // Get consonant class badge color
  const getConsonantClassBadgeColor = (consonantClass) => {
    switch (consonantClass) {
      case "low":
        return "danger"; // low class - red
      case "mid":
        return "primary"; // mid class - blue
      case "high":
        return "success"; // high class - green
      default:
        return "secondary"; // unknown - gray
    }
  };

  // Get tone display name
  const getToneDisplayName = (tone) => {
    const toneOption = toneOptions.find((option) => option.code === tone);
    return toneOption ? toneOption.name : "Unknown";
  };

  // Get consonant class display name
  const getConsonantClassDisplayName = (consonantClass) => {
    const classOption = consonantClassOptions.find(
      (option) => option.code === consonantClass
    );
    return classOption ? classOption.name : "Unknown";
  };

  // Add these new handler functions (near other handlers)
  const handleIPAChange = (wordIndex, newIPA) => {
    setTempIPAs({
      ...tempIPAs,
      [wordIndex]: newIPA,
    });
  };

  const handleIPASave = async (wordIndex) => {
    const newIPA = tempIPAs[wordIndex];
    if (newIPA !== undefined) {
      await updateIPA(wordIndex, newIPA);
      // Clear the temporary IPA after saving
      const updatedTempIPAs = { ...tempIPAs };
      delete updatedTempIPAs[wordIndex];
      setTempIPAs(updatedTempIPAs);
    }
  };

  // Add these new handler functions
  const handleGroupEdit = (groupId, field, value) => {
    setTempGroupEdits((prev) => ({
      ...prev,
      [groupId]: {
        ...(prev[groupId] || {}),
        [field]: value,
      },
    }));
  };

  const handleGroupSave = async (groupId) => {
    try {
      const groupEdits = tempGroupEdits[groupId];
      if (!groupEdits) return;

      const response = await api.updateGroup(groupId, groupEdits);
      setGroups(groups.map((g) => (g._id === groupId ? response.data : g)));

      // Clear temporary edits for this group
      setTempGroupEdits((prev) => {
        const newEdits = { ...prev };
        delete newEdits[groupId];
        return newEdits;
      });
      setError(null);
    } catch (err) {
      setError("Failed to update group");
      console.error("Error updating group:", err);
    }
  };

  const handleGroupDelete = async (groupId) => {
    if (window.confirm("Are you sure you want to delete this group?")) {
      try {
        await api.deleteGroup(groupId);
        setGroups(groups.filter((g) => g._id !== groupId));
        setError(null);
      } catch (err) {
        setError("Failed to delete group");
        console.error("Error deleting group:", err);
      }
    }
  };

  return (
    <Container className="py-3">
      <h1 className="mb-3">Thai Admin</h1>

      <Tabs defaultActiveKey="words" className="mb-3">
        <Tab eventKey="words" title="Words">
          {/* Add new word section */}
          <Card className="mb-3">
            <Card.Header as="h5">Add New Word</Card.Header>
            <Card.Body>
              <Form onSubmit={handleAddWord}>
                <Form.Group as={Row} className="mb-2">
                  <Col xs={8} sm={9}>
                    <Form.Control
                      type="text"
                      value={newWord}
                      onChange={(e) => setNewWord(e.target.value)}
                      placeholder="Enter Thai word"
                    />
                  </Col>
                  <Col xs={4} sm={3}>
                    <Button variant="primary" type="submit" className="px-3">
                      <PlusLg className="me-1" /> Add
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>

          {/* Word list section */}
          <Card>
            <Card.Header as="h5">Word List</Card.Header>
            <Card.Body className="p-2">
              {loading ? (
                <p>Loading words...</p>
              ) : error ? (
                <p className="text-danger">{error}</p>
              ) : words.length === 0 ? (
                <p className="text-muted">No words added yet.</p>
              ) : (
                <ListGroup variant="flush">
                  {words.map((word, wordIndex) => (
                    <ListGroup.Item key={wordIndex} className="mb-2 py-2">
                      <Row className="align-items-center">
                        <Col
                          xs={12}
                          md={4}
                          className="d-flex align-items-center mb-2"
                        >
                          <SpeechButton
                            word={word.syllable}
                            speakOnRender={false}
                          />
                          <span
                            style={{
                              fontSize: "1.5rem",
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            {word.syllable}
                          </span>
                          <div
                            className="d-flex"
                            style={{ padding: "0 5px", marginRight: "10px" }}
                          >
                            {word.graphemes.map((g, i) => {
                              let badgeStyle = {};
                              let textClass = "";

                              if (word.positions.initial_consonant === i) {
                                badgeStyle = {
                                  backgroundColor: "#f8d7da",
                                  border: "1px solid #f5c6cb",
                                };
                                textClass = "text-danger";
                              } else if (word.positions.vowel.includes(i)) {
                                badgeStyle = {
                                  backgroundColor: "#d4edda",
                                  border: "1px solid #c3e6cb",
                                };
                                textClass = "text-success";
                              } else {
                                badgeStyle = {
                                  backgroundColor: "#f8f9fa",
                                  border: "1px solid #dee2e6",
                                };
                                textClass = "text-dark";
                              }

                              return (
                                <div
                                  key={i}
                                  style={{
                                    ...badgeStyle,
                                    fontSize: "1.5rem", // Increased font size for graphemes
                                  }}
                                  className={`me-1 p-1 rounded ${textClass}`}
                                >
                                  {g}
                                </div>
                              );
                            })}
                          </div>
                          <div
                            className="d-flex align-items-center"
                            style={{ marginRight: "10px" }}
                          >
                            {word.ipa.map((ipa, i) => (
                              <div
                                key={i}
                                style={{
                                  backgroundColor:
                                    i === 0
                                      ? "#f8d7da"
                                      : i === 1
                                      ? "#d4edda"
                                      : "#f8f9fa",
                                  border: `1px solid ${
                                    i === 0
                                      ? "#f5c6cb"
                                      : i === 1
                                      ? "#c3e6cb"
                                      : "#dee2e6"
                                  }`,
                                  fontSize: "1rem",
                                  padding: "2px 4px",
                                  marginRight: "4px",
                                  borderRadius: "4px",
                                }}
                                className={
                                  i === 0
                                    ? "text-danger"
                                    : i === 1
                                    ? "text-success"
                                    : "text-dark"
                                }
                              >
                                {ipa}
                              </div>
                            ))}
                          </div>
                          <span
                            style={{ fontSize: "1rem", marginLeft: "10px" }}
                          >
                            {word.translation || "No translation"}
                          </span>
                        </Col>
                        <Col
                          xs={12}
                          md={2}
                          className="d-flex align-items-center mb-2"
                        >
                          <Dropdown className="mr-2">
                            <Dropdown.Toggle
                              variant={getConsonantClassBadgeColor(
                                word.consonantClass
                              )}
                              id={`dropdown-class-${wordIndex}`}
                              size="sm"
                              className="d-inline-flex align-items-center"
                            >
                              {getConsonantClassDisplayName(
                                word.consonantClass
                              )}{" "}
                              Class
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {consonantClassOptions.map((option) => (
                                <Dropdown.Item
                                  key={option.code}
                                  active={word.consonantClass === option.code}
                                  onClick={() =>
                                    updateConsonantClass(wordIndex, option.code)
                                  }
                                >
                                  {option.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                        <Col
                          xs={12}
                          md={2}
                          className="d-flex align-items-center mb-2"
                        >
                          <Dropdown className="mr-2">
                            <Dropdown.Toggle
                              variant={getToneBadgeColor(word.tone)}
                              id={`dropdown-tone-${wordIndex}`}
                              size="sm"
                              className="d-inline-flex align-items-center"
                            >
                              {getToneDisplayName(word.tone)} Tone
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {toneOptions.map((option) => (
                                <Dropdown.Item
                                  key={option.code}
                                  active={word.tone === option.code}
                                  onClick={() =>
                                    updateTone(wordIndex, option.code)
                                  }
                                >
                                  {option.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                        <Col
                          xs={12}
                          md={2}
                          className="d-flex align-items-center mb-2"
                        >
                          <Dropdown className="mr-2">
                            <Dropdown.Toggle variant="secondary" size="sm">
                              {word.groups?.length > 0
                                ? word.groups
                                    .map(
                                      (groupId) =>
                                        groups.find((g) => g._id === groupId)
                                          ?.name
                                    )
                                    .filter(Boolean)
                                    .join(", ")
                                : "Select Group"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {groups.map((group) => (
                                <Dropdown.Item
                                  key={group._id}
                                  active={word.groups?.includes(group._id)}
                                  onClick={() =>
                                    handleGroupSelect(wordIndex, group._id)
                                  }
                                >
                                  {group.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                        <Col
                          xs={12}
                          md={2}
                          className="d-flex gap-2 justify-content-end"
                        >
                          <Button
                            variant={
                              editingWordIndex === wordIndex
                                ? "secondary"
                                : "outline-secondary"
                            }
                            size="sm"
                            onClick={() => toggleEditWord(wordIndex)}
                            aria-label={
                              editingWordIndex === wordIndex
                                ? "Done editing"
                                : "Edit word"
                            }
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: "38px", height: "38px" }}
                          >
                            {editingWordIndex === wordIndex ? (
                              <Check size={16} />
                            ) : (
                              <Pencil size={16} />
                            )}
                          </Button>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleDeleteWord(wordIndex)}
                            aria-label="Delete word"
                            className="d-flex justify-content-center align-items-center"
                            style={{ width: "38px", height: "38px" }}
                          >
                            <Trash size={16} />
                          </Button>
                        </Col>
                      </Row>

                      {editingWordIndex === wordIndex && (
                        <Card className="mt-3 bg-light">
                          <Card.Body className="p-2">
                            <div className="mb-3">
                              <h5>Translation:</h5>
                              <div className="d-flex gap-2">
                                <Form.Control
                                  type="text"
                                  value={
                                    tempTranslations[wordIndex] ??
                                    word.translation
                                  }
                                  onChange={(e) =>
                                    handleTranslationChange(
                                      wordIndex,
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter translation"
                                  style={{ maxWidth: "300px" }}
                                />
                                <Button
                                  variant="success"
                                  size="sm"
                                  disabled={
                                    tempTranslations[wordIndex] === undefined ||
                                    tempTranslations[wordIndex] ===
                                      word.translation
                                  }
                                  onClick={() =>
                                    handleTranslationSave(wordIndex)
                                  }
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ width: "40px" }}
                                >
                                  <Check size={16} />
                                </Button>
                              </div>
                            </div>

                            <div className="mb-3">
                              <h5>Consonant Position:</h5>
                              <div className="d-flex flex-wrap gap-1 mt-2">
                                {word.graphemes.map((_, index) => (
                                  <Button
                                    key={index}
                                    variant={
                                      word.positions.initial_consonant === index
                                        ? "danger"
                                        : "outline-danger"
                                    }
                                    onClick={() =>
                                      setInitialConsonantPosition(
                                        wordIndex,
                                        index
                                      )
                                    }
                                    className="px-2 py-1"
                                    size="sm"
                                  >
                                    {index}
                                  </Button>
                                ))}
                              </div>
                            </div>

                            <div className="mb-3">
                              <h5>Vowel Positions:</h5>
                              <div className="d-flex flex-wrap gap-1 mt-2">
                                {word.graphemes.map((_, index) => (
                                  <Button
                                    key={index}
                                    variant={
                                      word.positions.vowel.includes(index)
                                        ? "success"
                                        : "outline-success"
                                    }
                                    onClick={() =>
                                      toggleVowelPosition(wordIndex, index)
                                    }
                                    className="px-2 py-1"
                                    size="sm"
                                  >
                                    {index}
                                  </Button>
                                ))}
                              </div>
                            </div>

                            <div className="mb-3">
                              <h5>Tone:</h5>
                              <div className="d-flex flex-wrap gap-1 mt-2">
                                {toneOptions.map((option) => (
                                  <Button
                                    key={option.code}
                                    variant={
                                      word.tone === option.code
                                        ? getToneBadgeColor(option.code)
                                        : `outline-${getToneBadgeColor(
                                            option.code
                                          )}`
                                    }
                                    onClick={() =>
                                      updateTone(wordIndex, option.code)
                                    }
                                    className="px-2 py-1"
                                    size="sm"
                                  >
                                    {option.name}
                                  </Button>
                                ))}
                              </div>
                              {word.analysis &&
                                word.tone !== word.analysis.tone && (
                                  <p className="text-muted fst-italic mt-1 small">
                                    Auto-detected:{" "}
                                    {getToneDisplayName(word.analysis.tone)} (
                                    {word.analysis.tone})
                                  </p>
                                )}
                            </div>

                            <div className="mb-3">
                              <h5>Consonant Class:</h5>
                              <div className="d-flex flex-wrap gap-1 mt-2">
                                {consonantClassOptions.map((option) => (
                                  <Button
                                    key={option.code}
                                    variant={
                                      word.consonantClass === option.code
                                        ? getConsonantClassBadgeColor(
                                            option.code
                                          )
                                        : `outline-${getConsonantClassBadgeColor(
                                            option.code
                                          )}`
                                    }
                                    onClick={() =>
                                      updateConsonantClass(
                                        wordIndex,
                                        option.code
                                      )
                                    }
                                    className="px-2 py-1"
                                    size="sm"
                                  >
                                    {option.name}
                                  </Button>
                                ))}
                              </div>
                              {word.analysis &&
                                word.consonantClass !==
                                  word.analysis.consonantClass && (
                                  <p className="text-muted fst-italic mt-1 small">
                                    Auto-detected:{" "}
                                    {getConsonantClassDisplayName(
                                      word.analysis.consonantClass
                                    )}{" "}
                                    ({word.analysis.consonantClass})
                                  </p>
                                )}
                            </div>

                            <div className="mb-3">
                              <h5>IPA:</h5>
                              <div className="d-flex gap-2">
                                <Form.Control
                                  type="text"
                                  value={
                                    tempIPAs[wordIndex] ?? word.ipa.join(", ")
                                  }
                                  onChange={(e) =>
                                    handleIPAChange(wordIndex, e.target.value)
                                  }
                                  placeholder="Enter IPA values separated by commas"
                                  style={{ maxWidth: "300px" }}
                                />
                                <Button
                                  variant="success"
                                  size="sm"
                                  disabled={
                                    tempIPAs[wordIndex] === undefined ||
                                    tempIPAs[wordIndex] === word.ipa.join(", ")
                                  }
                                  onClick={() => handleIPASave(wordIndex)}
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ width: "40px" }}
                                >
                                  <Check size={16} />
                                </Button>
                              </div>
                            </div>

                            <div className="mt-3">
                              <Badge
                                bg="secondary"
                                className="text-wrap text-start w-100 p-2"
                              >
                                <small>
                                  <strong>Analysis:</strong>{" "}
                                  {JSON.stringify(word.analysis, null, 2)}
                                </small>
                              </Badge>
                            </div>
                          </Card.Body>
                        </Card>
                      )}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        </Tab>

        <Tab eventKey="groups" title="Groups">
          <Form onSubmit={handleAddGroup}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                value={newGroup}
                onChange={(e) => setNewGroup(e.target.value)}
                placeholder="Enter a new group name"
              />
            </Form.Group>
            <Button type="submit">Add Group</Button>
          </Form>

          <ListGroup className="mt-3">
            {groups.map((group) => (
              <ListGroup.Item
                key={group._id}
                className="d-flex align-items-start gap-2"
              >
                <div className="flex-grow-1">
                  <div className="mb-2">
                    <Form.Control
                      type="text"
                      value={tempGroupEdits[group._id]?.name ?? group.name}
                      onChange={(e) =>
                        handleGroupEdit(group._id, "name", e.target.value)
                      }
                      placeholder="Group name"
                      className="mb-2"
                    />
                    <Form.Control
                      type="text"
                      value={
                        tempGroupEdits[group._id]?.description ??
                        group.description
                      }
                      onChange={(e) =>
                        handleGroupEdit(
                          group._id,
                          "description",
                          e.target.value
                        )
                      }
                      placeholder="Description"
                    />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <Button
                    variant="success"
                    size="sm"
                    disabled={!tempGroupEdits[group._id]}
                    onClick={() => handleGroupSave(group._id)}
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "38px", height: "38px" }}
                  >
                    <Check size={16} />
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => handleGroupDelete(group._id)}
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "38px", height: "38px" }}
                  >
                    <Trash size={16} />
                  </Button>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default ThaiAdmin;
