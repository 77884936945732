import React, { useState, useEffect } from "react";
import { Container, Table, Spinner } from "react-bootstrap";
import SpeechButton from "../Components/SpeechButton";

const ThaiCharacters = () => {
  const [characters, setCharacters] = useState([]);
  const [thaiWords, setThaiWords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCharacters = async () => {
    try {
      // NOTE: not retrieving unused characters here
      const response = await fetch("/api/thai/characters");
      if (!response.ok) {
        throw new Error("Failed to fetch characters");
      }
      const data = await response.json();
      setCharacters(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchThaiWords = async () => {
    const response = await fetch("/api/thai");
    if (!response.ok) {
      throw new Error("Failed to fetch Thai words");
    }
    const data = await response.json();
    setThaiWords(data);
    console.log("Thai words", data);
  };

  useEffect(() => {
    fetchCharacters();
    fetchThaiWords();
  }, []);

  const renderCharacterRow = (char) => (
    <tr key={char.character}>
      <td>
        <div className="d-flex align-items-center">
          <SpeechButton word={char.character} speakOnRender={false} />
          <div
            className="ms-2"
            style={{
              fontFamily: "'Noto Sans Thai', sans-serif",
              fontSize: "2em",
            }}
          >
            {char.character}
          </div>
        </div>
      </td>
      <td
        style={{
          fontFamily: "'Noto Sans Thai', sans-serif",
          fontSize: "1.2em",
        }}
      >
        {char.name_thai}
      </td>
      <td>{char.name_english}</td>
      <td>{char.notes}</td>
      <td>
        {thaiWords
          .filter((word) => word.graphemes.includes(char.character))
          .map((word) => (
            <span
              key={word.syllable}
              className="badge bg-primary me-1 mb-1"
              style={{ fontFamily: "'Noto Sans Thai', sans-serif" }}
            >
              {word.syllable}
            </span>
          ))}
      </td>
    </tr>
  );

  const renderSection = (title, characters) => (
    <>
      <tr className="bg-light">
        <th colSpan="5" className="h5 py-2">
          {title}
        </th>
      </tr>
      {characters.map(renderCharacterRow)}
    </>
  );

  if (loading) {
    return (
      <Container className="d-flex justify-content-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-4">
        <div className="alert alert-danger" role="alert">
          Error loading characters: {error}
        </div>
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Character</th>
            <th>Thai Name</th>
            <th>English Name</th>
            <th>Notes</th>
            <th>Example Words</th>
          </tr>
        </thead>
        <tbody>
          {renderSection(
            "Middle Class Consonants",
            characters.filter(
              (char) =>
                char.type === "consonant" && char.consonant_class === "middle"
            )
          )}
          {renderSection(
            "High Class Consonants",
            characters.filter(
              (char) =>
                char.type === "consonant" && char.consonant_class === "high"
            )
          )}
          {renderSection(
            "Low Class Consonants",
            characters.filter(
              (char) =>
                char.type === "consonant" && char.consonant_class === "low"
            )
          )}
          {renderSection(
            "Vowels",
            characters.filter((char) => char.type === "vowel")
          )}
          {renderSection(
            "Special Characters",
            characters.filter((char) => char.type === "special")
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default ThaiCharacters;
