import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Form, Alert, Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";

function LibraryImportBook() {
  const { t } = useTranslation();
  const context = useContext(AuthContext);
  const languageSettingRef = useRef(
    context.getSelectedLanguagePair().language_learning
  );
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");
  const [languages, setLanguages] = useState([]); // State for languages
  const [selectedLanguage, setSelectedLanguage] = useState(""); // State for selected language
  const [collectionId, setCollectionId] = useState(null);

  useEffect(() => {
    axios
      .get("/api/languages")
      .then((response) => {
        const sortedLanguages = response.data.languages.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setLanguages(sortedLanguages);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []); // Fetch languages on component mount

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const submitFile = () => {
    if (!file) {
      setError("Please select an EPUB file to upload.");
      return;
    }

    setError("");
    setImportSuccessMessage("");
    setIsLoading(true);

    const formData = new FormData();
    formData.append("epub", file);
    formData.append("language", languageSettingRef.current.code);

    axios
      .post("/api/books", formData, {
        timeout: 120000, // 2 minutes timeout in milliseconds
      })
      .then((response) => {
        console.log("File upload successful:", response.data);
        setImportSuccessMessage(t("library:import_book_success_alert_message"));
        setCollectionId(response.data.data.collectionId);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        setError(t("library:import_book_error_alert_message") + error.message);
        setIsLoading(false);
      });
  };

  return (
    <Container style={{ minHeight: "70vh" }}>
      <h2>{t("library:import_book_title")}</h2>
      <p>{t("library:import_book_description")}</p>
      <Form>
        <Form.Group controlId="formFileUpload">
          <Form.Label className="mt-3">
            <strong>{t("library:import_book_file_label")}</strong>
            <div className="mt-2">
              <Form.Text className="text-muted">
                {t("library:import_book_file_description")}
              </Form.Text>
            </div>
          </Form.Label>
          <Row>
            <Col md={4}>
              <Form.Control
                type="file"
                accept=".epub"
                onChange={handleFileChange}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="languageSetting" className="mt-3">
          <Form.Label>
            <strong>{t("library:collection_editor_language_setting")}</strong>
          </Form.Label>
          <Row>
            <Col md={4}>
              <Form.Control
                type="text"
                value={languageSettingRef.current.name}
                disabled
              />
            </Col>
          </Row>
          <Form.Text className="text-muted">
            {t("library:collection_editor_language_setting_description")}
          </Form.Text>
        </Form.Group>

        {error && (
          <Alert
            variant="danger"
            className="mt-3 mb-0"
            style={{ width: "fit-content" }}
          >
            {error}
          </Alert>
        )}

        {importSuccessMessage && (
          <Alert
            variant="success"
            className="mt-3 mb-0"
            style={{ width: "fit-content" }}
          >
            <p>{importSuccessMessage}</p>
            <hr />
            {collectionId && (
              <div className="d-flex justify-content-end mt-2">
                <Link
                  to={`/library/collection/editor/${collectionId}`}
                  className="btn btn-dark"
                >
                  {t("library:import_book_edit_collection_button")}
                </Link>
              </div>
            )}
          </Alert>
        )}

        <Button
          variant="primary"
          onClick={submitFile}
          disabled={isLoading}
          className="my-3"
        >
          {isLoading ? t("common:uploading") : t("common:upload")}
        </Button>

        {isLoading && (
          <Alert
            variant="info"
            className="mt-3 mb-0"
            style={{ width: "fit-content" }}
          >
            {t("library:import_book_uploading_message") ||
              "Importing book... This may take a few minutes."}
          </Alert>
        )}
      </Form>
    </Container>
  );
}

export default LibraryImportBook;
