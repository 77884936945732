import React, { useState, useEffect } from "react";
import { Button, Card, Container, Dropdown } from "react-bootstrap";
import SpeechButton from "../Components/SpeechButton";

const thaiSyllables = [
  // Group 1: Your provided syllables
  {
    syllable: "บะ",
    letters: ["บ", "ะ"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["b", "a"],
    tone: "l",
    translation: "exclamation/interjection",
    group: 1,
  },
  {
    syllable: "บา",
    letters: ["บ", "า"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["b", "aː"],
    tone: "m",
    translation: "monk",
    group: 1,
  },
  {
    syllable: "ปะ",
    letters: ["ป", "ะ"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["p", "a"],
    tone: "l",
    translation: "to patch or fix",
    group: 1,
  },
  {
    syllable: "ปา",
    letters: ["ป", "า"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["p", "aː"],
    tone: "m",
    translation: "to throw",
    group: 1,
  },
  // Group 2: Syllables from the new table
  {
    syllable: "ดิ",
    letters: ["ด"],
    graphemes: ["ด", "ิ"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["d", "i"],
    tone: "l",
    translation: "younger sister/brother (informal)",
    group: 2,
  },
  {
    syllable: "ดี",
    letters: ["ดี"],
    graphemes: ["ด", "ี"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["d", "iː"],
    tone: "m",
    translation: "good",
    group: 2,
  },
  {
    syllable: "ดะ",
    letters: ["ด", "ะ"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["d", "a"],
    tone: "l",
    translation: "to interrupt",
    group: 2,
  },
  {
    syllable: "ดา",
    letters: ["ด", "า"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["d", "aː"],
    tone: "m",
    translation: "a common Thai name",
    group: 2,
  },
  {
    syllable: "ติ",
    letters: ["ต", "ิ"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["t", "i"],
    tone: "l",
    translation: "to criticize",
    group: 2,
  },
  {
    syllable: "ตี",
    letters: ["ตี"],
    graphemes: ["ต", "ี"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["t", "iː"],
    tone: "m",
    translation: "to hit",
    group: 2,
  },
  {
    syllable: "ตะ",
    letters: ["ต", "ะ"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["t", "a"],
    tone: "l",
    translation: "tray (used in compound words)",
    group: 2,
  },
  {
    syllable: "ตา",
    letters: ["ต", "า"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["t", "aː"],
    tone: "m",
    translation: "eye; grandfather",
    group: 2,
  },
  // Group 3:
  {
    syllable: "กิน",
    letters: ["กิ", "น"],
    graphemes: ["ก", "ิ", "น"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
      final_consonant: [1],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["k", "i", "n"],
    tone: "m",
    translation: "to eat",
    group: 3,
  },
  {
    syllable: "กีด",
    letters: ["กี", "ด"],
    graphemes: ["ก", "ี", "ด"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["k", "iː", "t"],
    tone: "l",
    translation: "to block",
    group: 3,
  },
  {
    syllable: "กะ",
    letters: ["ก", "ะ"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["k", "a"],
    tone: "l",
    translation: "to guess; shift (at work)",
    group: 3,
  },
  {
    syllable: "กา",
    letters: ["ก", "า"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["k", "aː"],
    tone: "m",
    translation: "crow; coffee",
    group: 3,
  },
  {
    syllable: "จิบ",
    letters: ["จิ", "บ"],
    graphemes: ["จ", "ิ", "บ"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
      final_consonant: [1],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["t͡ɕ", "i", "p"],
    tone: "l",
    translation: "to sip",
    group: 3,
  },
  {
    syllable: "จัด",
    letters: ["จั", "ด"],
    graphemes: ["จ", "ั", "ด"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [1],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["t͡ɕ", "a", "t"],
    tone: "l",
    translation: "to organize",
    group: 3,
  },
  {
    syllable: "จาก",
    letters: ["จ", "า", "ก"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["t͡ɕ", "aː", "k"],
    tone: "l",
    translation: "from",
    group: 3,
  },
  {
    syllable: "อิ",
    letters: ["อิ"],
    graphemes: ["อ", "ิ"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["ʔ", "i"],
    tone: "l",
    translation: "interjection (e.g., to call attention)",
    group: 3,
  },
  {
    syllable: "อี",
    letters: ["อี"],
    graphemes: ["อ", "ี"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["ʔ", "iː"],
    tone: "m",
    translation: "female prefix; used for animals",
    group: 3,
  },
  {
    syllable: "อะ",
    letters: ["อ", "ะ"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["ʔ", "a"],
    tone: "l",
    translation: "interjection (like 'huh?')",
    group: 3,
  },
  {
    syllable: "อา",
    letters: ["อ", "า"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["ʔ", "aː"],
    tone: "m",
    translation: "uncle (father's younger brother)",
    group: 3,
  },
  // group 4
  {
    syllable: "กีด",
    letters: ["กี", "ด"],
    graphemes: ["ก", "ี", "ด"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["k", "iː", "t"],
    tone: "l",
    translation: "to block",
    group: 4,
  },
  {
    syllable: "ตึ",
    letters: ["ตึ"],
    graphemes: ["ต", "ึ"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["t", "ɯ"],
    tone: "l",
    translation: "building",
    group: 4,
  },
  {
    syllable: "ดึ",
    letters: ["ดึ"],
    graphemes: ["ด", "ึ"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["d", "ɯ"],
    tone: "l",
    translation: "to pull",
    group: 4,
  },
  {
    syllable: "กิน",
    letters: ["กิ", "น"],
    graphemes: ["ก", "ิ", "น"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
      final_consonant: [1],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["k", "i", "n"],
    tone: "m",
    translation: "to eat",
    group: 4,
  },
  {
    syllable: "จิบ",
    letters: ["จิ", "บ"],
    graphemes: ["จ", "ิ", "บ"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
      final_consonant: [1],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["t͡ɕ", "i", "p"],
    tone: "l",
    translation: "to sip",
    group: 4,
  },
  {
    syllable: "กือ",
    letters: ["กื", "อ"],
    graphemes: ["ก", "ื", "อ"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["k", "ɯː"],
    tone: "m",
    translation: "to be fat",
    group: 4,
  },
  {
    syllable: "จือ",
    letters: ["จื", "อ"],
    graphemes: ["จ", "ื", "อ"],
    positions: {
      initial_consonant: [0],
      vowel: [0, 1],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1, 2],
    },
    ipa: ["t͡ɕ", "ɯː"],
    tone: "m",
    translation: "name of a Chinese card game",
    group: 4,
  },
  {
    syllable: "ดึ",
    letters: ["ด", "ึ"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["d", "ɯ"],
    tone: "l",
    translation: "to pull",
    group: 6,
  },
  {
    syllable: "ดื",
    letters: ["ด", "ื"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["d", "ɯː"],
    tone: "m",
    translation: "to drink",
    group: 7,
  },
  {
    syllable: "ปือย",
    letters: ["ปื", "อ", "ย"],
    graphemes: ["ป", "ื", "อ", "ย"],
    positions: {
      initial_consonant: [0],
      vowel: [0, 1],
      final_consonant: [2],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1, 2],
      final_consonant: [3],
    },
    ipa: ["p", "ɯː", "j"],
    tone: "m",
    translation: "to be soggy",
    group: 4,
  },
  // group 5
  {
    syllable: "บุ",
    letters: ["บุ"],
    graphemes: ["บ", "ุ"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["b", "u"],
    tone: "l",
    translation: "to line (e.g., with fabric)",
    group: 5,
  },
  {
    syllable: "ปุ",
    letters: ["ปุ"],
    graphemes: ["ป", "ุ"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["p", "u"],
    tone: "l",
    translation: "grandfather (informal)",
    group: 5,
  },
  {
    syllable: "ดุบ",
    letters: ["ดุ", "บ"],
    graphemes: ["ด", "ุ", "บ"],
    positions: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["d", "u", "p"],
    tone: "l",
    translation: "to hit",
    group: 5,
  },
  {
    syllable: "กู",
    letters: ["กู"],
    graphemes: ["ก", "ู"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["k", "uː"],
    tone: "m",
    translation: "I (informal, rude)",
    group: 5,
  },
  {
    syllable: "ตู",
    letters: ["ตู"],
    graphemes: ["ต", "ู"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
    },
    ipa: ["t", "uː"],
    tone: "m",
    translation: "cabinet",
    group: 5,
  },
  {
    syllable: "ปูด",
    letters: ["ปู", "ด"],
    graphemes: ["ป", "ู", "ด"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
      final_consonant: [1],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["p", "uː", "t"],
    tone: "l",
    translation: "to swell",
    group: 5,
  },
  {
    syllable: "บุบ",
    letters: ["บุ", "บ"],
    graphemes: ["บ", "ุ", "บ"],
    positions: {
      initial_consonant: [0],
      vowel: [0],
      final_consonant: [1],
    },
    positions_graphemes: {
      initial_consonant: [0],
      vowel: [1],
      final_consonant: [2],
    },
    ipa: ["b", "u", "p"],
    tone: "l",
    translation: "to dent",
    group: 5,
  },
];

const allOptions = {
  TONE: [
    { name: "High", value: "h" },
    { name: "Falling", value: "f" },
    { name: "Mid", value: "m" },
    { name: "Rising", value: "r" },
    { name: "Low", value: "l" },
  ],
  CONSONANT: [
    { name: "ก", value: "ก" },
    { name: "จ", value: "จ" },
    { name: "ฎ", value: "ฎ" },
    { name: "ฏ", value: "ฏ" },
    { name: "ด", value: "ด" },
    { name: "ต", value: "ต" },
    { name: "บ", value: "บ" },
    { name: "ป", value: "ป" },
    { name: "อ", value: "อ" },
  ],
  VOWEL: [
    { name: "ะ", value: "ะ" },
    { name: "า", value: "า" },
    { name: "ิ", value: "ิ" },
    { name: "ี", value: "ี" },
    { name: "ึ", value: "ึ" },
    { name: "ื", value: "ื" },
    { name: "ุ", value: "ุ" },
    { name: "ู", value: "ู" },
  ],
};

const VOWEL_DIACRITICS_ABOVE = [
  "ิ", // sara i
  "ี", // sara ii
  "ึ", // sara ue
  "ื", // sara uee
  "็", // mai taikhu
];

const VOWEL_DIACRITICS_BELOW = [
  "ุ", // sara u
  "ู", // sara uu
];

const getRandomOptions = (allPossibleOptions, correctOption, count = 4) => {
  // Ensure we have enough options to choose from
  if (!correctOption || !allPossibleOptions || !allPossibleOptions.length) {
    console.warn("Invalid options or correct option");
    return [];
  }

  // If we don't have enough options, return all available options
  if (allPossibleOptions.length <= count) {
    return [...allPossibleOptions].sort(() => Math.random() - 0.5);
  }

  // Remove the correct option from the pool of possible options
  const otherOptions = allPossibleOptions.filter(
    (opt) => opt.value !== correctOption
  );

  // Shuffle the other options
  const shuffled = [...otherOptions].sort(() => Math.random() - 0.5);

  // Take count-1 random options
  const randomOptions = shuffled.slice(0, count - 1);

  // Add the correct option and shuffle again
  const result = [
    ...randomOptions,
    allPossibleOptions.find((opt) => opt.value === correctOption),
  ];
  return result.sort(() => Math.random() - 0.5);
};

const quizTypes = {
  TONE: {
    name: "Tone",
    options: allOptions.TONE, // Tones always show all options
    correctAnswer: (syllable) => syllable.tone,
    highlight: "vowel",
  },
  CONSONANT: {
    name: "Consonant",
    getOptions: (syllable) => {
      const correct = syllable.letters[syllable.positions.initial_consonant[0]];
      return getRandomOptions(allOptions.CONSONANT, correct);
    },
    correctAnswer: (syllable) =>
      syllable.letters[syllable.positions.initial_consonant[0]],
    highlight: "consonant",
  },
  VOWEL: {
    name: "Vowel",
    getOptions: (syllable) => {
      const vowelIndex = syllable.positions.vowel[0];
      const correct = syllable.graphemes
        ? syllable.graphemes[syllable.positions_graphemes.vowel[0]]
        : syllable.letters[vowelIndex];
      return getRandomOptions(allOptions.VOWEL, correct);
    },
    correctAnswer: (syllable) => {
      const vowelIndex = syllable.positions.vowel[0];
      return syllable.graphemes
        ? syllable.graphemes[syllable.positions_graphemes.vowel[0]]
        : syllable.letters[vowelIndex];
    },
    highlight: "vowel",
  },
};

function ThaiTones4() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSyllable, setCurrentSyllable] = useState(null);
  const [selectedTone, setSelectedTone] = useState(null);
  const [correctTone, setCorrectTone] = useState(null);
  const [correctCount, setCorrectCount] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(1);
  const [quizType, setQuizType] = useState("TONE");
  const [currentOptions, setCurrentOptions] = useState([]);

  const getFilteredSyllables = () => {
    return thaiSyllables.filter((syllable) => syllable.group === selectedGroup);
  };

  useEffect(() => {
    const filteredSyllables = getFilteredSyllables();
    if (filteredSyllables.length > 0) {
      const newIndex = Math.floor(Math.random() * filteredSyllables.length);
      const newSyllable = filteredSyllables[newIndex];

      setCurrentIndex(newIndex);
      setCurrentSyllable(newSyllable);
      console.log("Current Syllable:", newSyllable);

      // Update options based on the new syllable
      if (quizType === "TONE") {
        setCurrentOptions(quizTypes[quizType].options);
      } else {
        setCurrentOptions(quizTypes[quizType].getOptions(newSyllable));
      }
    } else {
      setCurrentIndex(-1);
      setCurrentSyllable(null);
      setCurrentOptions([]);
    }
  }, [selectedGroup, quizType]);

  const handleGuess = (guessedValue) => {
    if (!currentSyllable) return;

    const correct = quizTypes[quizType].correctAnswer(currentSyllable);
    setSelectedTone(guessedValue);
    setCorrectTone(correct);

    if (guessedValue === correct) {
      setCorrectCount((prevCount) => prevCount + 1);
    } else {
      setCorrectCount(0);
    }

    setTimeout(() => {
      const filteredSyllables = getFilteredSyllables();
      let nextIndex;
      do {
        nextIndex = Math.floor(Math.random() * filteredSyllables.length);
      } while (nextIndex === currentIndex && filteredSyllables.length > 1);

      const nextSyllable = filteredSyllables[nextIndex];
      setCurrentIndex(nextIndex);
      setCurrentSyllable(nextSyllable);

      // Update options for the new syllable
      if (quizType === "TONE") {
        setCurrentOptions(quizTypes[quizType].options);
      } else {
        setCurrentOptions(quizTypes[quizType].getOptions(nextSyllable));
      }

      setSelectedTone(null);
      setCorrectTone(null);
    }, 1000);
  };

  const renderSyllable = (syllableObj) => {
    return (
      <>
        {syllableObj.letters.map((char, index) => {
          const isVowel = syllableObj.positions.vowel.includes(index);
          const isConsonant =
            syllableObj.positions.initial_consonant.includes(index);
          const shouldHighlight =
            !syllableObj.graphemes &&
            ((quizType === "VOWEL" && isVowel) ||
              (quizType === "CONSONANT" && isConsonant) ||
              (quizType === "TONE" && isVowel));

          // Hide the character if it's being guessed
          const shouldHide =
            (quizType === "CONSONANT" && isConsonant) ||
            (quizType === "VOWEL" && isVowel);

          return (
            <span
              key={index}
              className={shouldHighlight ? "text-warning" : ""}
              style={{ fontSize: "4rem" }}
            >
              {shouldHide ? "?" : char}
            </span>
          );
        })}
      </>
    );
  };

  const renderGraphemes = (syllableObj) => {
    if (!syllableObj.graphemes) {
      console.log("No graphemes found for syllable:", syllableObj);
      return null;
    }

    console.log("Rendering graphemes for syllable:", {
      syllable: syllableObj.syllable,
      graphemes: syllableObj.graphemes,
      positions_graphemes: syllableObj.positions_graphemes,
      quizType: quizType,
    });

    // Get the initial consonant
    const initialConsonant =
      syllableObj.graphemes[
        syllableObj.positions_graphemes?.initial_consonant?.[0]
      ];

    return syllableObj.graphemes.map((char, index) => {
      const isVowelGrapheme =
        syllableObj.positions_graphemes?.vowel?.includes(index);
      const shouldHighlight = isVowelGrapheme;

      // Determine if this vowel is a diacritic above or below
      let specialStyle = {};
      if (isVowelGrapheme) {
        if (VOWEL_DIACRITICS_ABOVE.includes(char)) {
          specialStyle = {
            clipPath: "inset(0% 0% 70% 0%)",
            marginLeft: initialConsonant === "ป" ? "-35px" : "-25px",
            marginRight: initialConsonant === "ป" ? "10px" : "0px",
          };
        } else if (VOWEL_DIACRITICS_BELOW.includes(char)) {
          specialStyle = {
            clipPath: "inset(75% 0% 0% 0%)",
            marginLeft: "-25px",
          };
        }
      }

      console.log("Grapheme details:", {
        char,
        index,
        isVowelGrapheme,
        shouldHighlight,
        vowelPositions: syllableObj.positions_graphemes?.vowel,
      });

      return (
        <div
          key={index}
          className={shouldHighlight ? "text-warning" : ""}
          style={{
            fontSize: "3rem",
            display: "inline-block",
            ...specialStyle,
          }}
        >
          {char}
          {index < syllableObj.graphemes.length - 1 && " "}
        </div>
      );
    });
  };

  const renderIPA = (syllableObj) => {
    return (
      <>
        <span>/</span>
        {syllableObj.ipa.map((char, index) => {
          const isVowel = index === 1; // In this structure, IPA vowel is always the second element
          const isInitialConsonant = index === 0; // Initial consonant is always first element
          const shouldHighlight =
            (quizType === "VOWEL" && isVowel) ||
            (quizType === "CONSONANT" && isInitialConsonant) ||
            (quizType === "TONE" && isVowel);

          return (
            <span
              key={index}
              className={shouldHighlight ? "text-warning" : ""}
              style={{ fontSize: "2rem" }}
            >
              {char}
            </span>
          );
        })}
        <span>/</span>
      </>
    );
  };

  if (!currentSyllable) {
    return (
      <Container className="mt-5 d-flex flex-column align-items-center">
        <Dropdown className="mb-3">
          <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
            Group {selectedGroup}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setSelectedGroup(1)}>
              Group 1
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedGroup(2)}>
              Group 2
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedGroup(3)}>
              Group 3
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedGroup(4)}>
              Group 4
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSelectedGroup(5)}>
              Group 5
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div>No syllables available for this group.</div>
      </Container>
    );
  }

  return (
    <Container className="mt-5 d-flex flex-column align-items-center">
      <Dropdown className="mb-3 me-2">
        <Dropdown.Toggle variant="outline-dark">
          {quizTypes[quizType].name}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.entries(quizTypes).map(([key, value]) => (
            <Dropdown.Item key={key} onClick={() => setQuizType(key)}>
              {value.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="mb-3">
        <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
          Group {selectedGroup}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setSelectedGroup(1)}>
            Group 1
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSelectedGroup(2)}>
            Group 2
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSelectedGroup(3)}>
            Group 3
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSelectedGroup(4)}>
            Group 4
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSelectedGroup(5)}>
            Group 5
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Card
        className="text-center p-2 position-relative"
        style={{ minWidth: "300px", maxWidth: "400px" }}
      >
        <div style={{ position: "absolute", top: "10px", right: "10px" }}>
          <SpeechButton word={currentSyllable.syllable} speakOnRender={true} />
        </div>
        <Card.Body>
          {/* Only show Thai script for tone quizzes */}
          {quizType === "TONE" && (
            <>
              <Card.Title className="display-4">
                {renderSyllable(currentSyllable)}
              </Card.Title>
              {currentSyllable.graphemes && (
                <Card.Text className="fs-3">
                  {renderGraphemes(currentSyllable)}
                </Card.Text>
              )}
            </>
          )}

          <Card.Text className="fs-3">{renderIPA(currentSyllable)}</Card.Text>
          <Card.Text className="fs-5 text-muted">
            {currentSyllable.translation}
          </Card.Text>

          <div className="mt-4 d-flex flex-column align-items-center">
            {currentOptions &&
              currentOptions.length > 0 &&
              currentOptions.map(
                (option) =>
                  option &&
                  option.value && (
                    <Button
                      key={option.value}
                      variant={
                        selectedTone === option.value
                          ? selectedTone === correctTone
                            ? "success"
                            : "danger"
                          : correctTone === option.value
                          ? "success"
                          : "outline-dark"
                      }
                      onClick={() => handleGuess(option.value)}
                      style={{
                        width: "100px",
                        margin: "5px 0",
                        fontSize: quizType === "TONE" ? "1rem" : "2.5rem",
                      }}
                      size="sm"
                    >
                      {option.name}
                    </Button>
                  )
              )}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ThaiTones4;
