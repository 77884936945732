import React from "react";
import { Accordion, ProgressBar } from "react-bootstrap";
import useLevels from "./data/Levels";
import { useTranslation } from "react-i18next";

const LevelsAudio = ({ currentHoursCount: listeningTimeInSeconds }) => {
  const { t } = useTranslation();
  const levels = useLevels();

  const totalSeconds = Object.values(listeningTimeInSeconds).reduce(
    (sum, current) => sum + current,
    0
  );
  const listeningTimeInHours = totalSeconds / 3600;

  const getLevelProgress = (index) => {
    if (index < getCurrentLevelIndex()) {
      return 100; // Level completed
    } else if (index === getCurrentLevelIndex()) {
      const currentLevel = levels[index];
      const previousLevelRange = index > 0 ? levels[index - 1].range_hours : 0;
      const rangeBetweenLevels = currentLevel.range_hours - previousLevelRange;
      const progressWithinLevel = listeningTimeInHours - previousLevelRange;
      return (progressWithinLevel / rangeBetweenLevels) * 100;
    }
    return 0; // Level not started
  };

  const getCurrentLevelIndex = () => {
    return levels.findIndex(
      (level) => listeningTimeInHours <= level.range_hours
    );
  };

  const levelRangeText = (index) => {
    const rangeStart = index === 0 ? 0 : levels[index - 1].range_hours;
    return `${rangeStart}-${levels[index].range_hours}`;
  };

  return (
    <>
      <h3 className="text-center mt-5">
        {t("statistics:your_current_fluency_level")}
      </h3>
      <p className="text-center">
        {t("statistics:your_current_listening_level_description")}
      </p>
      <Accordion
        // NOTE: it doesn't open the correct accordion item when the page loads (although the value is correct), so, better not open any
        // defaultActiveKey={String(getCurrentLevelIndex())}
        className="mt-4 mb-5"
      >
        {levels.map((level, index) => (
          <Accordion.Item eventKey={String(index)} key={index}>
            <Accordion.Header>
              <div style={{ flex: 1 }}>
                {level.title} ({level.range_hours} {t("statistics:hours")})
              </div>
              <div
                style={{ flex: 1, display: "flex", justifyContent: "center" }}
              >
                <ProgressBar
                  now={getLevelProgress(index)}
                  variant={
                    getLevelProgress(index) === 100
                      ? "success"
                      : getLevelProgress(index) > 66
                      ? "warning"
                      : "primary"
                  }
                  label={
                    index === getCurrentLevelIndex()
                      ? `${Math.floor(getLevelProgress(index))}%`
                      : ""
                  }
                  style={{ width: "50%" }}
                />
              </div>
            </Accordion.Header>

            <Accordion.Body>{level.explainer_audio}</Accordion.Body>
            <Accordion.Body>
              <div className="fw-bold">{t("statistics:instructions")}</div>
              <div>{level.instructions_audio}</div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default LevelsAudio;
