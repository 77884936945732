import React, { Component } from "react";
import axiosInstance from "../utils/AxiosInstance";
import i18n from "i18next";

const AuthContext = React.createContext();

export class AuthProvider extends Component {
  // Define UPDATE_INTERVAL as a class property
  UPDATE_INTERVAL = 30; // seconds

  state = {
    user_id: undefined,
    email: undefined,
    settings: {
      quick_lookup_in_music: true,
      quick_lookup_in_reader: true,
      quick_lookup_in_audio_player: false,
      quick_lookup_in_chat: true,
      show_question_word_in_flashcards: true,
      show_translated_news_article_titles: true,
      expand_ai_explanation_box: false,
      scroll_automatically_in_audio_player: true,
      stop_after_each_sentence_in_audio_player: false,
      interests: undefined,
      interest_categories: undefined,
      ui_language: undefined,
    }, // NOTE: could theoretically already set quick_lookup settings here
    newsletter: undefined,
    plan: undefined,
    // plan_name: undefined,
    has_token: undefined,
    has_been_verified: undefined,
    translations: undefined,
    language_pairs: undefined,
    known_words: undefined, // NOTE: these are the known words for the currently selected language pair
    onboarding_shown: false,
    timezone: undefined,
    word_lookups_count: undefined,
    statistics: undefined,
    streaks: undefined,
    study_articles: [],
    selected_word_for_translation: undefined,
    selected_word_for_quick_translation: undefined,
    plan_word_lookups_count_limit_reached: false,
    audio_played_this_session: {
      seconds_played: 0,
    },
  };

  // check whether the user has reached limits for the free plan
  componentDidUpdate(prevProps, prevState) {
    const { plan, word_lookups_count } = this.state;

    if (
      plan === "Free plan" &&
      word_lookups_count >= 20 &&
      !prevState.plan_word_lookups_count_limit_reached
    ) {
      this.setState({ plan_word_lookups_count_limit_reached: true });
    }
  }

  // Mark a word as known
  markWordAsKnown = (word, translation) => {
    this.setState((prevState) => ({
      known_words: [...prevState.known_words, { word, translation }],
    }));
  };

  updateKnownWord = (word, translation, strength) => {
    // find the word in the known_words array based on the _id
    const index = this.state.known_words.findIndex(
      (knownWord) => knownWord._id === word._id
    );
    // update the word in the array
    let newWord = this.state.known_words[index];
    newWord.translation = translation;
    newWord.strength = strength;

    // Format request to match the expected structure
    const request = {
      words: [newWord],
    };

    // send the word to the backend using the correct endpoint
    axiosInstance
      .put("/api/user/update-words", request)
      .then((res) => {
        console.log("RESPONSE FROM UPDATE WORDS backend: ", res.data);
        // update the known_words array in the state
        this.setState({ known_words: [...this.state.known_words, newWord] });
      })
      .catch((err) => {
        console.error("ERROR WHEN UPDATING WORDS in backend:", err);
      });
  };

  addSkippedWords = (words) => {
    this.setState((prevState) => ({
      known_words: [...prevState.known_words, ...words],
    }));
  };

  saveNewWordToBackend = (word, article_id = undefined) => {
    console.log("Trying to save the new word to the backend: ", word);
    const request = {
      word: word,
      article_id: article_id,
    };

    return axiosInstance
      .put("/api/user/vocabulary/add", request)
      .then((response) => {
        console.log("RESPONSE VOCABULARY ADD WORDS backend: ", response.data);
        this.setState((prevState) => {
          // Ensure known_words is an array or initialize it as an empty array
          const known_words = Array.isArray(prevState.known_words)
            ? prevState.known_words
            : [];
          return {
            known_words: [...known_words, response.data.addedWord],
          };
        });
        console.log("Added new known word: ", response.data.addedWord);
        return response.data; // Ensure the resolved value of the promise is the response data
      })
      .catch((err) => {
        console.error("ERROR WHEN ADDING WORDS in backend:", err);
        throw err; // Propagate the error to the caller
      });
  };

  // Update the translation of a known word
  updateWordTranslation = (word, newTranslation) => {
    this.setState((prevState) => {
      const updatedKnownWords = prevState.known_words.map((kw) => {
        if (kw.word === word) {
          return { ...kw, translation: newTranslation };
        }
        return kw;
      });
      return { known_words: updatedKnownWords };
    });
  };

  // Delete a known word
  deleteKnownWord = (word) => {
    this.setState((prevState) => ({
      known_words: prevState.known_words.filter((kw) => kw.word !== word),
    }));
  };

  getKnownWordsSet = () => {
    console.trace("getKnownWordsSet called"); // This will show the call stack
    console.log("Getting known words set: ", this.state.known_words);
    const { known_words } = this.state;
    if (!Array.isArray(known_words) || known_words.length === 0) {
      return new Set(); // Return an empty set if known_words is not an array
    }
    // Convert all words to lowercase and add only the .word property to the set
    return new Set(known_words.map((kw) => kw?.word?.toLowerCase()));
  };

  getAuthInfo = () => {
    //const controller = new AbortController();
    console.log("STATE before Authentication: ", this.state);
    axiosInstance
      .get("/api/check-cookies", { timeout: 60000 }) // Set timeout to 1 minute (60000 ms)
      .then((res) => {
        console.log(
          "Auth data sent back from server inside res.data: ",
          res.data
        );
        // NOTE: it can only be undefined for the old users - the new ones will have the timezone set
        // NOTE: some older browsers might not support getting timezone, so setting it to UTC as a fallback
        const currentTimezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";
        if (res.data.timezone === undefined && res.data.user_id) {
          console.log("No timezone was found in the response data");
          // set the timezone in the state
          // NOTE: this is maybe not technically needed as the updateTimeZone will update the state as well
          res.data.timezone = currentTimezone;

          // send the timezone to the backend
          this.updateTimeZone(currentTimezone);
        }
        //console.log("YO: ", res.data.verified);
        // store user in the Context to make it available for all the children
        if (res.data.has_token !== "undefined") {

          // warn users against using text zoom on mobile devices
          if (window.innerWidth <= 768 && res.data.email !== undefined) {
            function detectTextZoom() {
              const testElement = document.createElement("div");
              testElement.style.fontSize = "16px"; // Default browser font size
              testElement.style.position = "absolute";
              testElement.style.visibility = "hidden";
              document.body.appendChild(testElement);

              const computedSize = window.getComputedStyle(testElement).fontSize;
              document.body.removeChild(testElement);

              return parseFloat(computedSize) / 16; // Ratio of actual size vs. default
            }

            const textZoomLevel = detectTextZoom();
            console.log("Text Zoom Factor:", textZoomLevel.toFixed(2));
            if (textZoomLevel > 1.25) {
              alert("It seems like you have text zoom enabled in your browser. \n\nPlease reset it to 100% to use the app properly (usually under browser settings or accessibility settings). \n\nOr contact the support if you have a feature request.");
            }
          }

          // find the selected language pairs
          // find the known words
          function findSelectedWords(data) {
            if (!data.language_pairs) {
              return [];
            }
            for (const pair of data.language_pairs) {
              if (pair.is_selected) {
                return pair.words;
              }
            }
            return [];
          }
          const selectedWords = findSelectedWords(res.data);

          // Check if ui_language is undefined and set it to browser language
          const settings = {
            ...this.state.settings,
            ...res.data.settings,
          };
          const browserLanguage = this.detectBrowserLanguage();
          console.log("Browser language: ", browserLanguage);

          // NOTE: setting the UI language currently only for logged in users
          if (res.data.has_token && settings.ui_language === undefined) {
            console.log(
              "UI language is undefined, setting it to browser language"
            );
            const browserLanguage = this.detectBrowserLanguage();
            settings.ui_language = browserLanguage;
            // Update the UI language in the backend
            this.updateUiLanguage(browserLanguage);
          } else if (settings.ui_language !== undefined && res.data.has_token) {
            console.log(
              "UI language set in the backend: ",
              settings.ui_language
            );
            i18n.changeLanguage(settings.ui_language);
          }

          this.setState(
            (prevState) => ({
              user_id: res.data.user_id,
              email: res.data.email,
              // NOTE: the settings are not stored in the database for the old users
              // NOTE: the user might still only have one setting and not some other ones
              settings: settings, // Use our modified settings object
              newsletter: res.data.newsletter,
              notifications: res.data.notifications,
              plan: res.data.plan,
              has_token: res.data.has_token,
              has_been_verified: res.data.has_been_verified,
              translations: res.data.translations,
              language_pairs: res.data.language_pairs,
              // known_words: selectedWords,
              timezone: res.data.timezone,
              word_lookups_count: res.data.word_lookups_count,
            }),
            () => {
              console.log(
                "Double check the state after adding values from the res.data: ",
                this.state
              );
            }
          );
        } else {
          this.setState({
            has_token: false,
          });
        }
        console.log(
          "AuthContext.js: User global context has been set to: ",
          this.state
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateTimeZone = (timezone) => {
    axiosInstance
      .post("/api/user/update-timezone", { timezone: timezone })
      .then((response) => {
        // Handle the response, e.g., update the time zone if it differs
        console.log("Timezone update response:", response.data);
        this.setState({ timezone });
        return response.data;
      })
      .catch((error) => {
        console.error("Error updating time zone:", error);
      });
  };

  detectBrowserLanguage = () => {
    // Try navigator.language first
    if (navigator && navigator.language) {
      return navigator.language;
    }

    // Fallback to default English
    return "en-US";
  };

  updateUiLanguage = (language) => {
    console.log("Updating UI language to: ", language);
    this.setState({ ui_language: language });
    // get languages list
    axiosInstance.get("/api/localization/languages").then((res) => {
      const supportedLanguages = res.data; // Array of full locale codes like ["en-US", "es-419"]
      console.log("Languages list: ", supportedLanguages);

      // Get first two letters of browser language
      const browserLangPrefix = language.slice(0, 2).toLowerCase();

      // Find matching supported language by comparing first two letters
      const matchingLanguage = supportedLanguages.find(
        (supported) => supported.slice(0, 2).toLowerCase() === browserLangPrefix
      );

      if (matchingLanguage) {
        console.log(`Found matching language: ${matchingLanguage}`);
        // Change the language using i18n
        console.log("Changing the language to: ", matchingLanguage);
        i18n.changeLanguage(matchingLanguage);
        // Make the API call to update the UI language using the full matching locale code
        axiosInstance
          .put(`/api/user/settings/ui-language`, {
            value: matchingLanguage,
          })
          .then((res) => {
            console.log(
              "Updated UI language setting in the server: ",
              res.data.message
            );
          });
      } else {
        console.log(
          `No matching language found for ${browserLangPrefix}, defaulting to English. No need to set anything, it's the default.`
        );
        // Default to English if no match found
        // i18n.changeLanguage("en-US");
      }
    });
  };

  // Method to fetch known words
  fetchKnownWords = async () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/user/known-words") // replace with your actual API endpoint
        .then((response) => {
          this.setState({ known_words: response.data.words }, () => {
            console.log(
              response.data.words.length,
              "known words fetched: ",
              response.data.message
            );
            resolve(response.data.words); // Resolve the promise after state update
          });
        })
        .catch((error) => {
          console.error("Error fetching known words:", error);
          reject(error); // Reject the promise on error
        });
    });
  };

  updateWordLookupsCount = (newCount) => {
    this.setState({ word_lookups_count: newCount });
  };

  updateKnownWordInContext = (wordToUpdate) => {
    if (!this.state || !this.state.known_words) {
      console.error("State or known_words is undefined");
      return;
    }

    const { known_words } = this.state;
    const updatedKnownWords = known_words.map((kw) => {
      if (kw._id === wordToUpdate._id) {
        return wordToUpdate;
      }
      return kw;
    });

    this.setState({ known_words: updatedKnownWords });
  };

  // fetch study articles
  fetchStudyArticles = async () => {
    return new Promise((resolve, reject) => {
      // get the language pair learning language code
      let languageLearningCode =
        this.getSelectedLanguagePair().language_learning.code;
      console.log(
        "Trying to fetch study articles for language: ",
        languageLearningCode
      );

      axiosInstance
        .get("/api/user/articles", {
          params: {
            language: languageLearningCode,
          },
        })
        .then((response) => {
          this.setState({ study_articles: response.data.articles }, () => {
            console.log(
              response.data.articles.length,
              "study articles fetched: ",
              response.data.message
            );
            resolve(response.data.articles); // Resolve the promise after state update
          });
        })
        .catch((error) => {
          console.error("Error fetching study articles:", error);
          reject(error); // Reject the promise on error
        });
    });
  };

  // NOTE: this sets statistics to an object (it's not an array)
  fetchStatistics = async () => {
    return new Promise((resolve, reject) => {
      let languagePairId = this.getSelectedLanguagePair()._id;
      axiosInstance
        .get(`/api/user/language-pairs/${languagePairId}/statistics`) // replace with your actual API endpoint
        .then((response) => {
          this.setState({ statistics: response.data }, () => {
            console.log("Fetched the statistics: ", response.data);
            resolve(response.data); // Resolve the promise after state update
          });
        })
        .catch((error) => {
          console.error("Error fetching the statistics:", error);
          reject(error); // Reject the promise on error
        });
    });
  };

  setOnboardingShownToTrue = () => {
    this.setState({ onboarding_shown: true });
  };

  // Add a new course
  addCourse = (courseData) => {
    axiosInstance
      .post("/api/courses", courseData)
      .then(() => {
        this.getAuthInfo(); // Refresh auth info after adding a course
      })
      .catch((error) => {
        console.error("Error adding course:", error);
      });
  };

  // Delete a course
  // NOTE: have to make sure that it's not possible to delete the last course
  deleteCourse = (courseId) => {
    axiosInstance
      .delete(`/api/courses/${courseId}`)
      .then(() => {
        this.getAuthInfo(); // Refresh auth info after deleting a course
      })
      .catch((error) => {
        console.error("Error deleting course:", error);
      });
  };

  // Set a course as active
  setActiveCourse = (courseId) => {
    console.log(
      "Trying to set the active course in context and backend to: ",
      courseId
    );
    // make axiosInstance call to backend
    axiosInstance
      .post(`/api/user/courses/active/${courseId}`)
      .then((res) => {
        console.log(
          "Active course set successfully with message: ",
          res.message
        );
        // force rerender of the page bc the Library wouldn't refresh itself currently (it doesn't use context)
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error setting active course:", error);
      });
  };

  // get the currently selected language pair
  getSelectedLanguagePair = () => {
    const { language_pairs } = this.state;
    for (const pair of language_pairs) {
      if (pair.is_selected) {
        return pair;
      }
    }
    return null;
  };

  // toggle settings value
  toggleQuickLookupInMusic = () => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      quick_lookup_in_music: !settings.quick_lookup_in_music,
    };
    this.setState({ settings: newSettings });
    console.log(
      "Toggled quick lookup in music to: ",
      newSettings.quick_lookup_in_music
    );
    axiosInstance
      .put(`/api/user/settings/quick-lookup-in-music`, {
        value: newSettings.quick_lookup_in_music,
      })
      .then((res) => {
        console.log(
          "Toggled quick lookup setting in music in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error(
          "Error toggling quick lookup in music in the server:",
          error
        );
      });
  };

  // toggle settings value
  toggleQuickLookupInReader = () => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      quick_lookup_in_reader: !settings.quick_lookup_in_reader,
    };
    this.setState({ settings: newSettings });
    console.log(
      "Toggled quick lookup in reader to: ",
      newSettings.quick_lookup_in_reader
    );
    axiosInstance
      .put(`/api/user/settings/quick-lookup-in-reader`, {
        value: newSettings.quick_lookup_in_reader,
      })
      .then((res) => {
        console.log(
          "Toggled quick lookup setting in reader in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error(
          "Error toggling quick lookup in reader in the server:",
          error
        );
      });
  };

  // toggle settings value
  toggleQuickLookupInAudioPlayer = () => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      quick_lookup_in_audio_player: !settings.quick_lookup_in_audio_player,
    };
    this.setState({ settings: newSettings });
    console.log(
      "Toggled quick lookup in audio player to: ",
      newSettings.quick_lookup_in_audio_player
    );
    axiosInstance
      .put(`/api/user/settings/quick-lookup-in-audio-player`, {
        value: newSettings.quick_lookup_in_audio_player,
      })
      .then((res) => {
        console.log(
          "Toggled quick lookup setting in audio player in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error(
          "Error toggling quick lookup in audio player in the server:",
          error
        );
      });
  };

  // toggle settings value
  toggleShowTranslatedNewsArticleTitles = () => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      show_translated_news_article_titles:
        !settings.show_translated_news_article_titles,
    };
    this.setState({ settings: newSettings });
    console.log(
      "Toggled show translated news article titles to: ",
      newSettings.show_translated_news_article_titles
    );
    axiosInstance
      .put(`/api/user/settings/show-translated-news-article-titles`, {
        value: newSettings.show_translated_news_article_titles,
      })
      .then((res) => {
        console.log(
          "Toggled show translated news article titles setting in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error(
          "Error toggling show translated news article titles setting in the server:",
          error
        );
      });
  };

  // toggle settings value
  toggleShowTranslatedNewsArticleTitles = () => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      show_translated_news_article_titles:
        !settings.show_translated_news_article_titles,
    };
    this.setState({ settings: newSettings });
    console.log(
      "Toggled show translated news article titles to: ",
      newSettings.show_translated_news_article_titles
    );
    axiosInstance
      .put(`/api/user/settings/show-translated-news-article-titles`, {
        value: newSettings.show_translated_news_article_titles,
      })
      .then((res) => {
        console.log(
          "Toggled show translated news article titles setting in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error(
          "Error toggling show translated news article titles setting in the server:",
          error
        );
      });
  };

  // toggle settings value
  toggleExpandAIExplanationBox = () => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      expand_ai_explanation_box: !settings.expand_ai_explanation_box,
    };
    this.setState({ settings: newSettings });
    console.log(
      "Toggled expand ai explanation box to: ",
      newSettings.expand_ai_explanation_box
    );
    axiosInstance
      .put(`/api/user/settings/expand-ai-explanation-box`, {
        value: newSettings.expand_ai_explanation_box,
      })
      .then((res) => {
        console.log(
          "Toggled expand ai explanation box setting in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error(
          "Error toggling expand ai explanation box setting in the server:",
          error
        );
      });
  };

  // toggle settings value
  toggleScrollAutomaticallyInAudioPlayer = () => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      scroll_automatically_in_audio_player:
        !settings.scroll_automatically_in_audio_player,
    };
    this.setState({ settings: newSettings });
    console.log(
      "Toggled scroll automatically in audio player to: ",
      newSettings.scroll_automatically_in_audio_player
    );
    axiosInstance
      .put(`/api/user/settings/scroll-automatically-in-audio-player`, {
        value: newSettings.scroll_automatically_in_audio_player,
      })
      .then((res) => {
        console.log(
          "Toggled scroll automatically in audio player setting in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error(
          "Error toggling scroll automatically in audio player setting in the server:",
          error
        );
      });
  };

  // toggle settings value
  toggleStopAfterEachSentenceInAudioPlayer = () => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      stop_after_each_sentence_in_audio_player:
        !settings.stop_after_each_sentence_in_audio_player,
    };
    this.setState({ settings: newSettings });
    console.log(
      "Toggled stop after each sentence in audio player to: ",
      newSettings.stop_after_each_sentence_in_audio_player
    );
    axiosInstance
      .put(`/api/user/settings/stop-after-each-sentence-in-audio-player`, {
        value: newSettings.stop_after_each_sentence_in_audio_player,
      })
      .then((res) => {
        console.log(
          "Toggled stop after each sentence in audio player setting in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error(
          "Error toggling stop after each sentence in audio player setting in the server:",
          error
        );
      });
  };

  // toggle settings value
  toggleShowQuestionWordInFlashcards = () => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      show_question_word_in_flashcards:
        !settings.show_question_word_in_flashcards,
    };
    this.setState({ settings: newSettings });
    console.log(
      "Toggled flashcards question word showing to: ",
      newSettings.show_question_word_in_flashcards
    );
    return;
    axiosInstance
      .put(`/api/user/settings/show-question-word-in-flashcards`, {
        value: newSettings.show_question_word_in_flashcards,
      })
      .then((res) => {
        console.log(
          "Toggled show question word in flashcards setting in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error(
          "Error toggling show question word in flashcards setting in the server:",
          error
        );
      });
  };

  toggleQuickLookupInChat = () => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      quick_lookup_in_chat: !settings.quick_lookup_in_chat,
    };
    this.setState({ settings: newSettings });
    console.log(
      "Toggled quick lookup in chat to: ",
      newSettings.quick_lookup_in_chat
    );
    axiosInstance
      .put(`/api/user/settings/quick-lookup-in-chat`, {
        value: newSettings.quick_lookup_in_chat,
      })
      .then((res) => {
        console.log(
          "Toggled quick lookup setting in chat in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error(
          "Error toggling quick lookup in chat in the server:",
          error
        );
      });
  };

  // Update interests
  updateInterests = (newInterests) => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      interests: newInterests,
    };
    this.setState({ settings: newSettings });
    console.log("Updated interests to: ", newInterests);
    axiosInstance
      .put(`/api/user/settings/interests`, {
        value: newInterests,
      })
      .then((res) => {
        console.log(
          "Updated interests setting in the server: ",
          res.data.message
        );
      })
      .catch((error) => {
        console.error("Error updating interests setting in the server:", error);
      });
  };

  updateInterestCategories = (newInterestCategories) => {
    const { settings } = this.state;
    const newSettings = {
      ...settings,
      interest_categories: newInterestCategories,
    };
    this.setState({ settings: newSettings });
    console.log("Updated interest categories to: ", newInterestCategories);
    // axiosInstance
    //   .put(`/api/user/settings/interest-categories`, {
    //     value: newInterestCategories,
    //   })
    //   .then((res) => {
    //     console.log(
    //       "Updated interest categories setting in the server: ",
    //       res.data.message
    //     );
    //   })
    //   .catch((error) => {
    //     console.error(
    //       "Error updating interest categories setting in the server:",
    //       error
    //     );
    //   });
  };

  updateAudioStatistics = (newSeconds) => {
    const { audio_played_this_session } = this.state;
    const totalSeconds = audio_played_this_session.seconds_played + newSeconds;

    // Only update if we have full 30 seconds to report
    const secondsToReport = Math.floor(totalSeconds / 30) * 30;

    if (secondsToReport >= 30) {
      // Send tracking request to server
      axiosInstance
        .post("/api/user/track-audio-time", {
          seconds: secondsToReport,
          language_pair_id: this.getSelectedLanguagePair()._id,
          timezone: this.state.timezone,
        })
        .then((response) => {
          console.log("Audio time tracked successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error tracking audio time:", error);
        });

      // Update state with remaining seconds (those that didn't make a full 30)
      this.setState({
        audio_played_this_session: {
          ...audio_played_this_session,
          seconds_played: totalSeconds - secondsToReport,
        },
      });
    } else {
      // Just update the accumulated seconds
      this.setState({
        audio_played_this_session: {
          ...audio_played_this_session,
          seconds_played: totalSeconds,
        },
      });
    }
  };

  render() {
    const {
      user_id,
      plan,
      email,
      settings,
      newsletter,
      notifications,
      has_token,
      has_been_verified,
      translations,
      language_pairs,
      known_words,
      onboarding_shown,
      timezone,
      word_lookups_count,
      statistics,
      streaks,
      study_articles,
      plan_word_lookups_count_limit_reached,
      audio_played_this_session,
    } = this.state;

    const {
      markWordAsKnown,
      updateWordTranslation,
      deleteKnownWord,
      fetchKnownWords,
      addSkippedWords,
      updateKnownWord,
      saveNewWordToBackend,
      updateWordLookupsCount,
      updateKnownWordInContext,
      getKnownWordsSet,
      fetchStatistics,
      setOnboardingShownToTrue,
      addCourse,
      deleteCourse,
      setActiveCourse,
      getSelectedLanguagePair,
      updateTimeZone,
      fetchStudyArticles,
      toggleQuickLookupInMusic,
      toggleQuickLookupInReader,
      toggleQuickLookupInAudioPlayer,
      toggleShowTranslatedNewsArticleTitles,
      toggleShowQuestionWordInFlashcards,
      toggleScrollAutomaticallyInAudioPlayer,
      toggleStopAfterEachSentenceInAudioPlayer,
      toggleExpandAIExplanationBox,
      toggleQuickLookupInChat,
      updateInterests,
      updateInterestCategories,
      updateUiLanguage,
      updateAudioStatistics,
    } = this;

    const { getAuthInfo } = this; // const {logOut} = this;  // this for later for having the logout function available in context

    return (
      <AuthContext.Provider
        value={{
          user_id,
          email,
          settings,
          newsletter,
          notifications,
          plan,
          has_token,
          has_been_verified,
          translations,
          language_pairs,
          known_words,
          onboarding_shown,
          timezone,
          statistics,
          word_lookups_count,
          streaks,
          study_articles,
          plan_word_lookups_count_limit_reached,
          audio_played_this_session,
          markWordAsKnown,
          updateWordTranslation,
          deleteKnownWord,
          getAuthInfo,
          updateTimeZone,
          fetchKnownWords,
          addSkippedWords,
          updateKnownWord,
          updateWordLookupsCount,
          updateKnownWordInContext,
          getKnownWordsSet,
          saveNewWordToBackend,
          fetchStatistics,
          setOnboardingShownToTrue,
          addCourse,
          deleteCourse,
          setActiveCourse,
          getSelectedLanguagePair,
          fetchStudyArticles,
          toggleQuickLookupInMusic,
          toggleQuickLookupInReader,
          toggleQuickLookupInAudioPlayer,
          toggleShowTranslatedNewsArticleTitles,
          toggleShowQuestionWordInFlashcards,
          toggleScrollAutomaticallyInAudioPlayer,
          toggleStopAfterEachSentenceInAudioPlayer,
          toggleExpandAIExplanationBox,
          toggleQuickLookupInChat,
          updateInterests,
          updateInterestCategories,
          updateUiLanguage,
          updateAudioStatistics,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContext;
