import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import SpeechButton from "../Components/SpeechButton";

const tones = [
  { name: "High", thai: "ป๊า", ipa: "páː", pattern: drawHighTone },
  { name: "Falling", thai: "ป้า", ipa: "pâː", pattern: drawFallingTone },
  { name: "Mid", thai: "ปา", ipa: "pāː", pattern: drawMidTone },
  { name: "Rising", thai: "ป๋า", ipa: "pǎː", pattern: drawRisingTone },
  { name: "Low", thai: "ป่า", ipa: "pàː", pattern: drawLowTone },
];

function drawMidTone(ctx) {
  ctx.beginPath();
  ctx.moveTo(10, 20);
  ctx.lineTo(90, 20);
  ctx.strokeStyle = "red";
  ctx.lineWidth = 2;
  ctx.stroke();
}

function drawLowTone(ctx) {
  ctx.beginPath();
  ctx.moveTo(10, 25);
  ctx.lineTo(70, 30);
  ctx.lineTo(90, 25);
  ctx.strokeStyle = "red";
  ctx.lineWidth = 2;
  ctx.stroke();
}

function drawFallingTone(ctx) {
  ctx.beginPath();
  ctx.moveTo(10, 10);
  ctx.lineTo(25, 5);
  ctx.lineTo(90, 30);
  ctx.strokeStyle = "red";
  ctx.lineWidth = 2;
  ctx.stroke();
}

function drawHighTone(ctx) {
  ctx.beginPath();
  ctx.moveTo(10, 18);
  ctx.lineTo(90, 10);
  ctx.strokeStyle = "red";
  ctx.lineWidth = 2;
  ctx.stroke();
}

function drawRisingTone(ctx) {
  ctx.beginPath();
  ctx.moveTo(10, 35);
  ctx.lineTo(20, 40);
  ctx.lineTo(90, 10);
  ctx.strokeStyle = "red";
  ctx.lineWidth = 2;
  ctx.stroke();
}

const ThaiTones = () => {
  const [wordToSpeak, setWordToSpeak] = useState("");

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col xs={12} className="d-flex justify-content-center flex-wrap gap-3">
          {tones.map((tone) => (
            <ToneButton
              key={tone.name}
              tone={tone}
              onToneClick={() => setWordToSpeak(tone.thai)}
            />
          ))}
        </Col>
      </Row>
      <div style={{ position: "fixed", top: "10px", right: "10px" }}>
        <SpeechButton word={wordToSpeak} speakOnRender={true} />
      </div>
    </Container>
  );
};

const ToneButton = ({ tone, onToneClick }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      tone.pattern(ctx);
    }
  }, [tone]);

  return (
    <Button
      variant="outline-secondary"
      className="d-flex flex-column align-items-center p-2"
      style={{ width: "120px" }}
      onClick={() => {
        onToneClick();
        console.log(`You clicked on the ${tone.name} tone`);
      }}
    >
      <div style={{ width: "100px", height: "40px" }}>
        <canvas ref={canvasRef} width={100} height={40} />
      </div>
      <hr className="w-100 my-2" />
      <div className="text-center">
        <div>
          <strong>{tone.name}</strong>
        </div>
        <div>{tone.ipa}</div>
        <div>{tone.thai}</div>
      </div>
    </Button>
  );
};

export default ThaiTones;
