import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Trophy, TrophyFill } from "react-bootstrap-icons";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";

const StreakWeek = () => {
  const context = React.useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const [streakDates, setStreakDates] = useState([]);

  useEffect(() => {
    context.getAuthInfo();
  }, []);

  // create useEffect based on content to set streakDates
  useEffect(() => {
    context.fetchStatistics().then((statistics) => {
      setStreakDates(statistics.wordsReadByDay);
      console.log("setStreakDates to: ", statistics.wordsReadByDay);
    });
    // setStreakDates(context.getSelectedLanguagePair().reading_stats);
    // console.log("setStreakDates to: ", context.getSelectedLanguagePair().reading_stats);
  }, []);

  // Converts a date to a standardized format (YYYY-MM-DD) considering local timezone
  const formatDate = (date) => {
    const d = new Date(date);
    return (
      d.getFullYear() +
      "-" +
      String(d.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(d.getDate()).padStart(2, "0")
    );
  };

  const getCurrentWeekDates = () => {
    let week = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to midnight in local time

    // Find the previous Sunday in local time
    const sunday = new Date(today);
    sunday.setDate(sunday.getDate() - sunday.getDay());

    for (let i = 0; i < 7; i++) {
      let day = new Date(sunday);
      day.setDate(sunday.getDate() + i);
      week.push(formatDate(day));
    }
    return week;
  };

  const checkStreak = (date) => {
    // Check if any object in streakDates has the specified date
    const hasStreak = streakDates.some(
      (streakDate) => streakDate.date === date
    );

    if (hasStreak) {
      console.log("Streak found for date:", date);
    } else {
      console.log("No streak for date:", date);
    }

    return hasStreak;
  };

  const weekDates = getCurrentWeekDates();

  return (
    <>
      <h3 className="text-center mt-0">
        {context.statistics?.streakCount}{" "}
        {t("statistics:weekly_streak_x_day_streak")}
      </h3>
      <Card
        className="text-center mb-3 mt-4 bg-dark"
        style={{ maxWidth: "500px", margin: "0 auto" }}
      >
        <Row className="justify-content-center my-2">
          {weekDates.map((date) => (
            <Col
              key={date}
              xs={1}
              className={`d-flex flex-column align-items-center text-white mx-2`} // ${checkStreak(date) ? "bg-success" : ""}
              style={{ padding: "10px" }}
            >
              <strong>
                {new Date(date).toLocaleDateString(i18n.language, {
                  weekday: "narrow",
                })}
              </strong>
              {checkStreak(date) ? (
                <TrophyFill color="yellow" className="mt-3 mb-2" size={20} />
              ) : (
                <Trophy color="gray" className="mt-3 mb-2" size={20} />
              )}
            </Col>
          ))}
        </Row>
      </Card>
      <p className="text-center pb-5">
        {t("statistics:weekly_streak_description")}
      </p>
    </>
  );
};

export default StreakWeek;
