import React, { useState, useEffect } from "react";
import { Alert, Button, Row, Nav, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SongCard from "./SongCard";
import VideoCard from "./VideoCard";
import Playlist from "./Playlist";
import axios from "axios";

const LibraryTabListening = ({
  checkIfArticleIsBookmarked,
  languageLearningCode,
  activeSubTab,
  onSubTabSelect,
}) => {
  const { t } = useTranslation();
  const [songs, setSongs] = useState([]);
  const [songsAreLoading, setSongsAreLoading] = useState(false);
  const [recentVideos, setRecentVideos] = useState([]);
  const [recentVideosAreLoading, setRecentVideosAreLoading] = useState(false);
  const [selectedVideoCategories, setSelectedVideoCategories] = useState(
    new Set()
  );
  const [uniqueVideoCategories, setUniqueVideoCategories] = useState([]);

  const getSongs = async () => {
    setSongsAreLoading(true);
    try {
      const response = await axios.get(`/api/songs`, {
        params: {
          language: languageLearningCode,
        },
      });
      setSongs(response.data.songs);
    } catch (error) {
      console.error("Error getting songs:", error);
    } finally {
      setSongsAreLoading(false);
    }
  };

  const getRecentVideos = async () => {
    setRecentVideosAreLoading(true);
    try {
      const response = await axios.get("/api/recent-videos", {
        params: {
          language: languageLearningCode,
        },
      });
      setRecentVideos(response.data.videos);

      // Extract unique categories
      const categoriesSet = new Set();
      response.data.videos.forEach((video) => {
        categoriesSet.add(video.category);
      });
      setUniqueVideoCategories(Array.from(categoriesSet).sort());
    } catch (error) {
      console.error("Error getting videos:", error);
    } finally {
      setRecentVideosAreLoading(false);
    }
  };

  useEffect(() => {
    if (activeSubTab === "songs") {
      getSongs();
    } else if (activeSubTab === "videos") {
      getRecentVideos();
    }
  }, [activeSubTab]);

  // Handle checkbox toggle for video category filter
  const handleVideoCategoryToggle = (category) => {
    const newSelectedCategories = new Set(selectedVideoCategories);
    if (newSelectedCategories.has(category)) {
      newSelectedCategories.delete(category);
    } else {
      newSelectedCategories.add(category);
    }
    setSelectedVideoCategories(newSelectedCategories);
  };

  // Filter videos by selected categories
  const categoryFilteredVideos =
    selectedVideoCategories.size === 0
      ? recentVideos
      : recentVideos.filter((video) =>
          selectedVideoCategories.has(video.category)
        );

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Nav variant="pills" className="mb-3">
        <Nav.Item>
          <Nav.Link
            eventKey="videos"
            active={activeSubTab === "videos"}
            onClick={() => onSubTabSelect("videos")}
            className={
              activeSubTab === "videos" ? "bg-warning text-dark" : "text-dark"
            }
          >
            {t("library:videos")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="songs"
            active={activeSubTab === "songs"}
            onClick={() => onSubTabSelect("songs")}
            className={
              activeSubTab === "songs" ? "bg-warning text-dark" : "text-dark"
            }
          >
            {t("library:songs")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="playlists"
            active={activeSubTab === "playlists"}
            onClick={() => onSubTabSelect("playlists")}
            className={
              activeSubTab === "playlists"
                ? "bg-warning text-dark"
                : "text-dark"
            }
          >
            {t("library:playlists")}
          </Nav.Link>
        </Nav.Item>
      </Nav>

      {activeSubTab === "videos" && (
        <>
          <div className="mb-3 mx-2">
            <h6>{t("library:filter_by_category")}</h6>
            {uniqueVideoCategories.map((category, index) => (
              <div className="form-check form-check-inline" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`video-category-${index}`}
                  checked={selectedVideoCategories.has(category)}
                  onChange={() => handleVideoCategoryToggle(category)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`video-category-${index}`}
                >
                  {capitalizeFirstLetter(category)}
                </label>
              </div>
            ))}
          </div>

          {!recentVideosAreLoading && categoryFilteredVideos.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; {t("library:no_videos_to_show_yet")}
            </Alert>
          )}

          {recentVideosAreLoading && (
            <Spinner animation="border" variant="dark" className="mt-4" />
          )}

          <Row className="mb-5 mt-4">
            {categoryFilteredVideos.map((video, index) => (
              <VideoCard video={video} index={index} key={index} />
            ))}
          </Row>
        </>
      )}

      {activeSubTab === "songs" && (
        <>
          <div>
            <Link to="/library/songs/editor">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center btn-dark"
              >
                &#43; {t("library:add_song")}
              </Button>
            </Link>
          </div>
          {songsAreLoading && (
            <Spinner animation="border" variant="dark" className="mt-4" />
          )}
          {!songsAreLoading && songs.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; {t("library:no_songs_yet")}
            </Alert>
          )}
          <Row className="mb-5 mt-4">
            {songs.map((song, index) => (
              <SongCard
                song={song}
                index={index}
                key={index}
                is_imported={false}
                is_bookmarked={checkIfArticleIsBookmarked(song._id)}
              />
            ))}
          </Row>
        </>
      )}

      {activeSubTab === "playlists" && <Playlist />}
    </>
  );
};

export default LibraryTabListening;
