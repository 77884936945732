import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Tabs, Tab, Alert } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import AppMetaDataContext from "../context/AppMetaDataContext";
import AuthContext from "../context/AuthContext";
import { Helmet } from "react-helmet-async";
import ContinueStudying from "../Components/ContinueStudying";
import { ArrowUp, ChatLeft } from "react-bootstrap-icons";
import "./Library.css";
import ChecklistProgress from "../Components/ChecklistProgress";
import { useTranslation } from "react-i18next";
import LibraryTabImported from "../Components/LibraryTabImported";
import LibraryTabListening from "../Components/LibraryTabListening";
import LibraryTabReading from "../Components/LibraryTabReading";
import LibraryTabWriting from "../Components/LibraryTabWriting";

export default function Library() {
  const metaData = React.useContext(AppMetaDataContext);
  const context = React.useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Initialize states
  const [key, setKey] = useState(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromURL = queryParams.get("tab");

    if (tabFromURL) {
      localStorage.setItem("lastSelectedTab", tabFromURL);
      return tabFromURL;
    }

    return localStorage.getItem("lastSelectedTab") || "reading";
  });

  const [subKey, setSubKey] = useState(() => {
    const queryParams = new URLSearchParams(location.search);
    const subTabFromURL = queryParams.get("subtab");

    if (subTabFromURL) {
      localStorage.setItem("lastSelectedSubTab", subTabFromURL);
      return subTabFromURL;
    }

    return localStorage.getItem("lastSelectedSubTab") || "news";
  });

  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [showChatText, setShowChatText] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChatText(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleTabSelect = (k) => {
    setKey(k);
    localStorage.setItem("lastSelectedTab", k);

    // Set default subtab based on the selected main tab
    let defaultSubTab;
    switch (k) {
      case "reading":
        defaultSubTab = "news";
        break;
      case "imported":
        defaultSubTab = "texts";
        break;
      case "listening":
        defaultSubTab = "videos"; // Add default subtab for listening
        break;
      default:
        defaultSubTab = null;
    }

    // Update URL, removing subtab if the new tab doesn't have subtabs
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("tab", k);
    if (defaultSubTab) {
      queryParams.set("subtab", defaultSubTab);
      setSubKey(defaultSubTab);
      localStorage.setItem("lastSelectedSubTab", defaultSubTab);
    } else {
      queryParams.delete("subtab");
      setSubKey(null); // Clear subKey for tabs without subtabs
    }

    navigate(`?${queryParams.toString()}`);
  };

  const handleSubTabSelect = (sk) => {
    setSubKey(sk);
    localStorage.setItem("lastSelectedSubTab", sk);

    // Update URL with both parameters
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("subtab", sk);
    navigate(`?${queryParams.toString()}`);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    const subtab = queryParams.get("subtab");

    if (tab) {
      setKey(tab);
      // If no subtab is provided, set the default based on the tab
      if (!subtab) {
        let defaultSubTab;
        switch (tab) {
          case "reading":
            defaultSubTab = "news";
            break;
          case "imported":
            defaultSubTab = "texts";
            break;
          case "listening":
            defaultSubTab = "videos";
            break;
          default:
            defaultSubTab = null;
        }

        if (defaultSubTab) {
          setSubKey(defaultSubTab);
          localStorage.setItem("lastSelectedSubTab", defaultSubTab);
          // Update URL with the default subtab
          queryParams.set("subtab", defaultSubTab);
          navigate(`?${queryParams.toString()}`);
        }
      }
    } else {
      setKey("reading");
      setSubKey("news");
    }

    if (subtab) {
      setSubKey(subtab);
    }
  }, [location]);

  useEffect(() => {
    // Fetch study articles when component mounts
    context.fetchStudyArticles();
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    console.log(
      "location changed, so, should start fetching new known words",
      window.location.href
    );
    context.fetchKnownWords();
  }, [window.location.href]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const checkIfArticleIsBookmarked = (articleId) => {
    return context.study_articles.some((article) => article._id === articleId);
  };

  return (
    <Container
      className="d-flex pt-2 flex-column justify-content-top mb-5"
      style={{ minHeight: "70vh" }}
    >
      <Helmet>
        <title>
          {t("library:library")} - {metaData.appName}
        </title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div className="mb-2 col-12 col-md-9 col-lg-6">
        <ChecklistProgress studyArticles={context.study_articles} />
      </div>
      {/* <div className="mb-4 col-12 col-md-9 col-lg-6">
        <div className="d-flex align-items-center">
          <Alert variant="light" className="d-flex align-items-center">
            <span className="me-2">
              {t("library:feature_request_title")}
            </span>
            <Button
              size="sm"
              variant="dark"
              onClick={() => window.open("https://tally.so/r/wbW5X1", "_blank")}
            >
              {t("library:feature_request_button")} &rarr;
            </Button>
          </Alert>
        </div>
      </div> */}

      {context.study_articles.length > 0 && <ContinueStudying />}

      <div
        style={{
          "& .navTabs": {
            minWidth: "100%",
            overflowX: "auto",
          },
        }}
      >
        <Tabs
          activeKey={key}
          id="library-tabs"
          onSelect={handleTabSelect}
          className="mb-3"
          style={{
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            overflowY: "hidden",
            height: "42px",
            whiteSpace: "nowrap",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Tab
            eventKey="reading"
            title={t("library:reading")}
            tabClassName="flex-shrink-0"
          >
            <LibraryTabReading
              checkIfArticleIsBookmarked={checkIfArticleIsBookmarked}
              languageLearningCode={
                context.getSelectedLanguagePair().language_learning.code
              }
              context={context}
              activeSubTab={subKey}
              onSubTabSelect={handleSubTabSelect}
            />
          </Tab>

          <Tab
            eventKey="listening"
            title={t("library:listening")}
            tabClassName="flex-shrink-0"
          >
            <LibraryTabListening
              checkIfArticleIsBookmarked={checkIfArticleIsBookmarked}
              languageLearningCode={
                context.getSelectedLanguagePair().language_learning.code
              }
              activeSubTab={subKey}
              onSubTabSelect={handleSubTabSelect}
            />
          </Tab>

          <Tab
            eventKey="writing"
            title={t("library:writing")}
            tabClassName="flex-shrink-0"
          >
            <LibraryTabWriting />
          </Tab>

          <Tab
            eventKey="imported"
            title={t("library:tab_imported")}
            tabClassName="flex-shrink-0"
          >
            <LibraryTabImported
              checkIfArticleIsBookmarked={checkIfArticleIsBookmarked}
              languageLearningCode={
                context.getSelectedLanguagePair().language_learning.code
              }
              activeSubTab={subKey}
              onSubTabSelect={handleSubTabSelect}
            />
          </Tab>
        </Tabs>
      </div>

      {showScrollToTop && (
        <Button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: window.innerWidth <= 992 ? "100px" : "80px", // Position above the chat button
            right: "20px", // Keep on the right side
            borderRadius: "50%", // Make it circular
            zIndex: 1000,
            backgroundColor: window.innerWidth <= 992 ? "white" : "lightblue",
            borderColor: window.innerWidth <= 992 ? "lightgray" : "lightblue",
            color: "black",
            width: "50px", // Fixed width
            height: "50px", // Fixed height
            padding: "0.8rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ArrowUp />
        </Button>
      )}

      {/* Chat Button */}
      <Button
        onClick={() => navigate("/chat")}
        className="btn-dark chat-button d-none d-lg-block"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          borderRadius: showChatText ? "20px" : "50%",
          zIndex: 1000,
          color: "white",
          padding: "0.8rem",
          transition: "all 0.5s ease",
          width: showChatText ? "160px" : "50px",
          height: "50px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: `translate(-50%, -50%) ${
              showChatText ? "translateX(-25px)" : "translateX(0)"
            }`,
            opacity: showChatText ? 1 : 0,
            transition: "all 0.5s ease",
            whiteSpace: "nowrap",
            marginRight: "25px",
          }}
        >
          {t("library:chat_with_ai")}
        </div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: `translate(-50%, -50%) ${
              showChatText ? "translateX(35px)" : "translateX(0)"
            }`,
            transition: "all 0.5s ease",
          }}
        >
          <ChatLeft />
        </div>
      </Button>
    </Container>
  );
}
