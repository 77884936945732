import React, { useState, useEffect } from "react";
import { Modal, ListGroup, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import ReactMarkdown from "react-markdown";

export default function ReaderSimplifyGrammarTensesModal({
  show,
  onHide,
  handleSimplification,
}) {
  const { t } = useTranslation();
  const context = React.useContext(AuthContext);
  const [tenses, setTenses] = useState([]);
  const [tensesContent, setTensesContent] = useState({});

  useEffect(() => {
    const fetchTenses = async () => {
      try {
        const response = await fetch("/api/tenses", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            language_string:
              context.getSelectedLanguagePair().language_learning.name,
          }),
        });
        const data = await response.json();
        setTenses(data.tenses);

        // Fetch markdown content for each tense
        const contents = {};
        for (const tense of data.tenses) {
          const language = context
            .getSelectedLanguagePair()
            .language_learning.name.toLowerCase();
          try {
            const formattedTense = tense.toLowerCase().replace(/\s+/g, "-");
            const markdownResponse = await fetch(
              `/grammar-guides/${language}/tenses/${formattedTense}.md`
            );
            const markdownContent = await markdownResponse.text();

            // Extract only the Examples section
            const examplesMatch = markdownContent.match(
              /## Examples:\s*([\s\S]*?)(?=##|$)/
            );
            contents[tense] = examplesMatch ? examplesMatch[1].trim() : null;
          } catch (error) {
            console.error(`Error fetching markdown for tense ${tense}:`, error);
            contents[tense] = null;
          }
        }
        setTensesContent(contents);
      } catch (error) {
        console.error("Error fetching tenses:", error);
      }
    };

    if (show) {
      fetchTenses();
    }
  }, [show, context.getSelectedLanguagePair]);

  return (
    <Modal show={show} onHide={onHide} size="lg" fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("grammar_guide:grammar_chat_modal_title")}{" "}
          <span>
            <sup>
              <span className="badge bg-warning text-dark ms-2">
                {t("common:beta")}
              </span>
            </sup>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t("grammar_guide:grammar_chat_modal_description_for_simplification")}
        </p>
        <ListGroup>
          {tenses.map((tense) => (
            <ListGroup.Item key={tense} className="d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center w-100">
                <h6>{tense}</h6>
                <Button
                  variant="primary"
                  onClick={() => handleSimplification("grammar", tense)}
                >
                  {t("grammar_guide:button_practice")}
                </Button>
              </div>
              {tensesContent[tense] && (
                <div className="mt-3">
                  <ReactMarkdown>{tensesContent[tense]}</ReactMarkdown>
                </div>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}
