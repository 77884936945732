import { useTranslation } from "react-i18next";

const useLevels = () => {
  const { t } = useTranslation();

  return [
    {
      title: t("statistics:levels_newbie"),
      range: 500,
      range_hours: 50,
      explainer: t("statistics:levels_newbie_explainer"),
      explainer_audio: t("statistics:levels_newbie_explainer_audio"),
      instructions: t("statistics:levels_newbie_instructions"),
      instructions_audio: t("statistics:levels_newbie_instructions_audio"),
    },
    {
      title: t("statistics:levels_beginner"),
      range: 1000,
      range_hours: 100,
      explainer: t("statistics:levels_beginner_explainer"),
      explainer_audio: t("statistics:levels_beginner_explainer_audio"),
      instructions: t("statistics:levels_beginner_instructions"),
      instructions_audio: t("statistics:levels_beginner_instructions_audio"),
    },
    {
      title: t("statistics:levels_elementary"),
      range: 2000,
      range_hours: 200,
      explainer: t("statistics:levels_elementary_explainer"),
      explainer_audio: t("statistics:levels_elementary_explainer_audio"),
      instructions: t("statistics:levels_elementary_instructions"),
      instructions_audio: t("statistics:levels_elementary_instructions_audio"),
    },
    {
      title: t("statistics:levels_intermediate"),
      range: 4000,
      range_hours: 400,
      explainer: t("statistics:levels_intermediate_explainer"),
      explainer_audio: t("statistics:levels_intermediate_explainer_audio"),
      instructions: t("statistics:levels_intermediate_instructions"),
      instructions_audio: t("statistics:levels_intermediate_instructions_audio"),
    },
    {
      title: t("statistics:levels_upper_intermediate"),
      range: 8000,
      range_hours: 800,
      explainer: t("statistics:levels_upper_intermediate_explainer"),
      explainer_audio: t("statistics:levels_upper_intermediate_explainer_audio"),
      instructions: t("statistics:levels_upper_intermediate_instructions"),
      instructions_audio: t("statistics:levels_upper_intermediate_instructions_audio"),
    },
    {
      title: t("statistics:levels_advanced"),
      range: 16000,
      range_hours: 1600,
      explainer: t("statistics:levels_advanced_explainer"),
      explainer_audio: t("statistics:levels_advanced_explainer_audio"),
      instructions: t("statistics:levels_advanced_instructions"),
      instructions_audio: t("statistics:levels_advanced_instructions_audio"),
    },
    {
      title: t("statistics:levels_proficient"),
      range: 32000,
      range_hours: 3200,
      explainer: t("statistics:levels_proficient_explainer"),
      explainer_audio: t("statistics:levels_proficient_explainer_audio"),
      instructions: t("statistics:levels_proficient_instructions"),
      instructions_audio: t("statistics:levels_proficient_instructions_audio"),
    },
    {
      title: t("statistics:levels_native"),
      range: 64000,
      range_hours: 6400,
      explainer: t("statistics:levels_native_explainer"),
      explainer_audio: t("statistics:levels_native_explainer_audio"),
      instructions: t("statistics:levels_native_instructions"),
      instructions_audio: t("statistics:levels_native_instructions_audio"),
    },
  ];
};

export default useLevels;
