import React, { useState, useEffect } from "react";
import { Button, Card, Container } from "react-bootstrap";
import SpeechButton from "../Components/SpeechButton";

const thaiSyllables = [
  {
    syllable: "กะ",
    vowel: "ะ",
    ipa: "/kà/",
    ipaVowel: "à",
    vowelLength: "short",
    tone: "l",
    translation: "particle",
  },
  {
    syllable: "กา",
    vowel: "า",
    ipa: "/kaː/",
    ipaVowel: "aː",
    vowelLength: "long",
    tone: "m",
    translation: "crow",
  },
  {
    syllable: "จะ",
    vowel: "ะ",
    ipa: "/tɕà/",
    ipaVowel: "à",
    vowelLength: "short",
    tone: "l",
    translation: "will",
  },
  {
    syllable: "จา",
    vowel: "า",
    ipa: "/tɕaː/",
    ipaVowel: "aː",
    vowelLength: "long",
    tone: "m",
    translation: "record",
  },
  {
    syllable: "ตะ",
    vowel: "ะ",
    ipa: "/tà/",
    ipaVowel: "à",
    vowelLength: "short",
    tone: "l",
    translation: "drumstick",
  },
  {
    syllable: "ตา",
    vowel: "า",
    ipa: "/taː/",
    ipaVowel: "aː",
    vowelLength: "long",
    tone: "m",
    translation: "eye",
  },
  {
    syllable: "ปะ",
    vowel: "ะ",
    ipa: "/pà/",
    ipaVowel: "à",
    vowelLength: "short",
    tone: "l",
    translation: "to meet",
  },
  {
    syllable: "ปา",
    vowel: "า",
    ipa: "/paː/",
    ipaVowel: "aː",
    vowelLength: "long",
    tone: "m",
    translation: "to throw",
  },
  {
    syllable: "นะ",
    vowel: "ะ",
    ipa: "/nà/",
    ipaVowel: "à",
    vowelLength: "short",
    tone: "l",
    translation: "softener",
  },
  {
    syllable: "นา",
    vowel: "า",
    ipa: "/naː/",
    ipaVowel: "aː",
    vowelLength: "long",
    tone: "m",
    translation: "field",
  },
  {
    syllable: "ละ",
    vowel: "ะ",
    ipa: "/là/",
    ipaVowel: "à",
    vowelLength: "short",
    tone: "l",
    translation: "to abandon",
  },
  {
    syllable: "ลา",
    vowel: "า",
    ipa: "/laː/",
    ipaVowel: "aː",
    vowelLength: "long",
    tone: "m",
    translation: "donkey / to say goodbye",
  },
  {
    syllable: "สะ",
    vowel: "ะ",
    ipa: "/sà/",
    ipaVowel: "à",
    vowelLength: "short",
    tone: "l",
    translation: "prefix (clean), ha!, oh!",
  },
  {
    syllable: "สา",
    vowel: "า",
    ipa: "/saː/",
    ipaVowel: "aː",
    vowelLength: "long",
    tone: "m",
    translation: "to pray",
  },
  {
    syllable: "ตี",
    vowel: "ี",
    ipa: "/tiː/",
    ipaVowel: "iː",
    vowelLength: "long",
    tone: "m",
    translation: "to hit",
  },
  {
    syllable: "มี",
    vowel: "ี",
    ipa: "/miː/",
    ipaVowel: "iː",
    vowelLength: "long",
    tone: "m",
    translation: "to have",
  },
  {
    syllable: "สี",
    vowel: "ี",
    ipa: "/siː/",
    ipaVowel: "iː",
    vowelLength: "long",
    tone: "m",
    translation: "color",
  },
  {
    syllable: "ดู",
    vowel: "ู",
    ipa: "/duː/",
    ipaVowel: "uː",
    vowelLength: "long",
    tone: "m",
    translation: "to watch",
  },
  {
    syllable: "อยู่",
    vowel: "ู",
    ipa: "/juː/",
    ipaVowel: "uː",
    vowelLength: "long",
    tone: "m",
    translation: "to be",
  },
  {
    syllable: "ครู",
    vowel: "ู",
    ipa: "/kʰruː/",
    ipaVowel: "uː",
    vowelLength: "long",
    tone: "m",
    translation: "teacher",
  },
  {
    syllable: "เต",
    vowel: "เ",
    ipa: "/teː/",
    ipaVowel: "eː",
    vowelLength: "long",
    tone: "m",
    translation: "soft-shelled turtle",
  },
  {
    syllable: "เม",
    vowel: "เ",
    ipa: "/meː/",
    ipaVowel: "eː",
    vowelLength: "long",
    tone: "m",
    translation: "to boast",
  },
  {
    syllable: "แท",
    vowel: "แ",
    ipa: "/tʰɛː/",
    ipaVowel: "ɛː",
    vowelLength: "long",
    tone: "m",
    translation: "real, true",
  },
  {
    syllable: "แพ",
    vowel: "แ",
    ipa: "/pʰɛː/",
    ipaVowel: "ɛː",
    vowelLength: "long",
    tone: "m",
    translation: "raft",
  },
  {
    syllable: "โต",
    vowel: "โ",
    ipa: "/toː/",
    ipaVowel: "oː",
    vowelLength: "long",
    tone: "m",
    translation: "to grow",
  },
  {
    syllable: "โบ",
    vowel: "โ",
    ipa: "/boː/",
    ipaVowel: "oː",
    vowelLength: "long",
    tone: "m",
    translation: "bow, ribbon",
  },
  {
    syllable: "เจอ",
    vowel: "เ-อ",
    ipa: "/tɕɤː/",
    ipaVowel: "ɤː",
    vowelLength: "long",
    tone: "m",
    translation: "to meet",
  },
  {
    syllable: "เธอ",
    vowel: "เ-อ",
    ipa: "/tʰɤː/",
    ipaVowel: "ɤː",
    vowelLength: "long",
    tone: "m",
    translation: "you",
  },
];


function ThaiIPAQuiz() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedIPA, setSelectedIPA] = useState(null);
  const [correctIPA, setCorrectIPA] = useState(null);
  const [correctCount, setCorrectCount] = useState(0);
  const [ipaOptions, setIpaOptions] = useState([]);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * thaiSyllables.length);
    setCurrentIndex(randomIndex);
    generateRandomIPAOptions(randomIndex);
  }, []);

  const generateRandomIPAOptions = (index) => {
    const correct = thaiSyllables[index].correctIPA;
    const allIPA = ["a", "aː", "e", "ɛ", "o", "ɔ", "ɯ", "u"]; // Common Thai vowel IPA symbols
    const shuffledIPA = allIPA.sort(() => 0.5 - Math.random()).slice(0, 4);

    if (!shuffledIPA.includes(correct)) {
      shuffledIPA[Math.floor(Math.random() * shuffledIPA.length)] = correct;
    }

    setIpaOptions(shuffledIPA);
  };

  const handleGuess = (guessedIPA) => {
    const currentSyllable = thaiSyllables[currentIndex];
    const correct = currentSyllable.correctIPA;
    setSelectedIPA(guessedIPA);
    setCorrectIPA(correct);

    if (guessedIPA === correct) {
      setCorrectCount((prevCount) => prevCount + 1);
    } else {
      setCorrectCount(0);
    }

    setTimeout(() => {
      let nextIndex;
      do {
        nextIndex = Math.floor(Math.random() * thaiSyllables.length);
      } while (nextIndex === currentIndex && thaiSyllables.length > 1);
      setCurrentIndex(nextIndex);
      generateRandomIPAOptions(nextIndex);
      setSelectedIPA(null);
      setCorrectIPA(null);
    }, 1000);
  };

  const getVowelColor = (vowelLength) => {
    return vowelLength === "long" ? "text-warning" : "text-danger"; // Yellow for long, Red for short
  };

  const renderSyllable = (syllableObj) => {
    return syllableObj.syllable.split("").map((char, index) => {
      const isVowel = syllableObj.vowel.includes(char);
      return (
        <span
          key={index}
          className={isVowel ? getVowelColor(syllableObj.vowelLength) : ""}
        >
          {char}
        </span>
      );
    });
  };

  const renderIPA = (syllableObj) => {
    return syllableObj.ipa.split("").map((char, index) => {
      const isVowel = syllableObj.ipaVowel.includes(char);
      return (
        <span
          key={index}
          className={isVowel ? getVowelColor(syllableObj.vowelLength) : ""}
        >
          {char}
        </span>
      );
    });
  };

  if (thaiSyllables.length === 0) {
    return <div>Loading...</div>;
  }

  const currentSyllable = thaiSyllables[currentIndex];

  return (
    <Container className="mt-5 d-flex justify-content-center align-items-center">
      <Card
        className="text-center p-4"
        style={{ minWidth: "300px", maxWidth: "400px" }}
      >
        <Card.Body>
          <Card.Title className="display-4">
            {renderSyllable(currentSyllable)}
          </Card.Title>
          <Card.Text className="fs-5 text-muted">
            {currentSyllable.translation}
          </Card.Text>

          <SpeechButton word={currentSyllable.syllable} speakOnRender={true} />

          <div className="mt-4 d-flex flex-column align-items-center">
            {ipaOptions.map((ipaOption) => (
              <Button
                key={ipaOption}
                variant={
                  selectedIPA === ipaOption
                    ? selectedIPA === correctIPA
                      ? "success"
                      : "danger"
                    : correctIPA === ipaOption
                    ? "success"
                    : "outline-dark"
                }
                onClick={() => handleGuess(ipaOption)}
                style={{ width: "100px", margin: "5px 0" }}
                size="sm"
              >
                {ipaOption}
              </Button>
            ))}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ThaiIPAQuiz;
